import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { BsFillPersonFill } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { MdFileUpload } from 'react-icons/md';
import '../CSS/Swag.css';
const BASE_URL = `${process.env.REACT_APP_SERVER_API_ADDRESS}`;

const Swag = () => {
  useEffect(() => {
    document.title = `Swag - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);
  const [formValues, setFormValues] = useState({
    name: '',
    company: '',
    email: '',
    address: '',
    city: '',
    state: '',
    photos: [],
    swagOption: 'umbrella',
  });

  function checkEmptyStrings() {
    for (const value of Object.values(formValues)) {
      if (typeof value === 'string' && value.trim() === '') {
        return false; // Found an empty string, return false
      }
    }
    return true; // All values are non-empty strings or other types
  }

  // const [formComplete, setFormComplete] = useState()
  // const [activeForm, setActiveForm] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false);
  const nameElement = useRef(null);
  const companyElement = useRef(null);
  const emailElement = useRef(null);
  const addressElement = useRef(null);
  const cityElement = useRef(null);
  const stateElement = useRef(null);
  function checkFormComplete() {
    return Object.keys(formValues).every((x) => {
      return formValues[x] !== '';
    });
  }
  function checkFocus() {
    return (
      nameElement.current !== document.activeElement &&
      companyElement.current !== document.activeElement &&
      emailElement.current !== document.activeElement &&
      addressElement.current !== document.activeElement &&
      cityElement.current !== document.activeElement &&
      stateElement.current !== document.activeElement
    );
  }

  useEffect(() => {
    if (checkFormComplete() && checkFocus()) {
      const section = document.querySelector('#swag-banner');
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [formValues]);

  useEffect(() => {
    if (errorMsg) {
      const timer = setTimeout(() => {
        setErrorMsg();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [errorMsg]);

  function sendForm() {
    if (checkEmptyStrings()) {
      const formData = new FormData();

      Object.values(formValues.photos).forEach((file) => {
        formData.append('arrayOfFiles', file);
      });
      formData.append('formValues', JSON.stringify(formValues));
      Axios.post(`${BASE_URL}/forms/swagUpload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(() => {
          setErrorMsg('Submission sent! Thank you!');
          setFormValues({
            name: '',
            company: '',
            email: '',
            address: '',
            city: '',
            state: '',
            photos: [],
            swagOption: 'umbrella',
          });
        })
        .catch(() => {
          setErrorMsg('Error submitting. Please try again later');
        });
    } else {
      setErrorMsg('Complete fields before submitting');
    }
  }
  const inputFile = useRef(null);
  function onInputChange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }
  function onInputChangeFiles(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.files });
  }
  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className='swag'>
      <div className='shot-banner'></div>
      <div style={{ height: '550px' }}></div>
      <div className='nps-banner'>
        <form>
          <div>
            <div className={`input-div`}>
              <BsFillPersonFill />
              <input
                ref={nameElement}
                name='name'
                value={formValues.name}
                type='text'
                onChange={onInputChange}
                placeholder='Your Name'
                maxLength={45}
              ></input>
            </div>
            <div className='input-div'>
              <BsFillPersonFill />
              <input
                ref={companyElement}
                name='company'
                value={formValues.company}
                type='text'
                onChange={onInputChange}
                placeholder='Company'
                maxLength={75}
              ></input>
            </div>
            <div className='input-div'>
              <BsFillPersonFill />
              <input
                ref={emailElement}
                name='email'
                value={formValues.email}
                type='email'
                onChange={onInputChange}
                placeholder='Email Address'
                maxLength={75}
              ></input>
            </div>
          </div>
          <div>
            <div className='input-div'>
              <BsFillPersonFill />
              <input
                ref={addressElement}
                name='address'
                value={formValues.address}
                type='text'
                onChange={onInputChange}
                placeholder='Address'
                maxLength={95}
              ></input>
            </div>
            <div className='cityState'>
              <div className='input-div'>
                <input
                  ref={cityElement}
                  name='city'
                  value={formValues.city}
                  type='text'
                  onChange={onInputChange}
                  placeholder='City'
                  maxLength={25}
                ></input>
              </div>
              <div className='input-div'>
                <input
                  ref={stateElement}
                  name='state'
                  value={formValues.state}
                  type='text'
                  onChange={onInputChange}
                  placeholder='State'
                  maxLength={25}
                ></input>
              </div>
            </div>
            <div className='upload'>
              <div
                role='button'
                aria-label='upload icon'
                tabIndex={0}
                className='svg'
                onClick={onButtonClick}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onButtonClick();
                  }
                }}
              >
                {formValues.photos.length ?
                  <FaCheck />
                : <MdFileUpload />}
              </div>
              <h2>
                {formValues.photos.length ?
                  `${formValues.photos.length} File${
                    formValues.photos.length > 1 ? 's' : ''
                  } Uploaded`
                : 'Upload Photos'}
              </h2>
              <input
                type='file'
                name='photos'
                onChange={onInputChangeFiles}
                id='file'
                ref={inputFile}
                style={{ display: 'none' }}
                multiple={true}
              />
            </div>
          </div>
        </form>
      </div>
      <div style={{ height: '800px' }}></div>
      <div
        className='swag-banner'
        id='swag-banner'
      >
        <div className='radio-buttons'>
          <input
            type='radio'
            value='umbrella'
            name='swagOption'
            onChange={onInputChange}
            checked={formValues.swagOption === 'umbrella'}
          />
          Inverted Travel Umbrella<br></br>
          <input
            type='radio'
            value='lunchbox'
            name='swagOption'
            onChange={onInputChange}
            checked={formValues.swagOption === 'lunchbox'}
          />
          Modular Lunchbox
          <br></br>
          <input
            type='radio'
            value='waterbottle'
            name='swagOption'
            onChange={onInputChange}
            checked={formValues.swagOption === 'waterbottle'}
          />
          Contigo AutoSeal Water Bottle
        </div>
        <button
          onClick={sendForm}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              sendForm();
            }
          }}
        >
          SUBMIT
        </button>
        <div
          className='error-msg-text'
          style={{
            position: 'absolute',
            top: '54.5rem',
            left: '16.75rem',
            fontSize: '18px',
          }}
        >
          {errorMsg ? errorMsg : ''}
        </div>
      </div>
      <div style={{ height: '900px' }}></div>
    </div>
  );
};

export default Swag;
