import Axios from 'axios';
import { useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const AdvancedSearch = ({
  searchFilters,
  setSearchFilters,
  searchTextObjects,
  setSearchTextObjects,
}) => {
  const [filterOpen, setFilterOpen] = useState();
  const [errMsg, setErrMsg] = useState('');
  const [productCerts, setProductCerts] = useState();
  const [searchCerts, setSearchCerts] = useState('');
  const searchOptions = ['Starts With', 'Exact Match', 'Ends With', 'Includes'];
  const searchSections = [
    { name: 'Model Number', column: 'i.model_num' },
    { name: 'Series', column: 'i.series' },
    { name: 'Short Description', column: 'i.short_description' },
    { name: 'Certification', column: 'pc.certification' },
  ];
  const navigate = useNavigate();

  function changeFilterOpen(section) {
    if (filterOpen === section) {
      setFilterOpen('');
    } else {
      setFilterOpen(section);
    }
  }

  function handleSearchChange(option, section) {
    setErrMsg('');
    const optionFound = searchFilters.some(
      (item) => item.option === option && item.section === section
    );
    if (optionFound) {
      setSearchFilters(
        searchFilters.filter((i) => (i.section === section ? i.option !== option : i))
      );
    } else {
      const newOption = {
        section: section,
        option: option,
      };
      setSearchFilters([...searchFilters, newOption]);
    }
  }

  function handleSearchTextChange(section, newText) {
    setErrMsg('');
    const existingItemIndex = searchTextObjects.findIndex((item) => item.section === section.name);
    if (existingItemIndex !== -1) {
      const updatedSearchText = [...searchTextObjects];
      updatedSearchText[existingItemIndex] = {
        ...updatedSearchText[existingItemIndex],
        text: newText,
      };
      setSearchTextObjects(updatedSearchText);
    } else {
      setSearchTextObjects([
        ...searchTextObjects,
        {
          section: section.name,
          text: newText,
          column: section.column,
        },
      ]);
    }
  }

  function inFilter(section) {
    return searchOptions.filter((option) =>
      searchFilters?.some((item) => item.option === option && item.section === section.name)
    );
  }

  function inTextInput(section) {
    return searchTextObjects?.find((item) => item.section === section.name)?.text;
  }

  async function handleSubmit() {
    setFilterOpen('');
    // Check if searchTextObjects contain sections not present in searchFilters
    const searchTextObjectsMissing = searchTextObjects
      .filter((i) => i.text !== '')
      .filter((item) => !searchFilters?.some((filter) => filter.section === item.section));
    // Check if searchFilters contain sections not present in searchTextObjects
    const searchFiltersMissing = searchFilters
      ?.filter((i) => i.section !== 'Certification')
      ?.filter(
        (filter) =>
          !searchTextObjects
            .filter((i) => i.text !== '')
            ?.some((item) => item.section === filter.section)
      );
    if (
      searchTextObjects.length < 1 &&
      searchFilters?.filter((i) => i.section !== 'Certification')?.length < 1
    ) {
      setErrMsg('*Invalid Entry');
      return;
    } else if (searchTextObjectsMissing.length > 0 || searchFiltersMissing.length > 0) {
      setErrMsg('*Must include text and filters for all filter options chosen to filter by');
      return;
    } else {
      setErrMsg('');
      navigate(`/search`, {
        state: {
          searchFilters: searchFilters,
          searchTextObjects: searchTextObjects.filter((i) => i.text !== ''),
        },
      });
    }
  }

  async function getProductCertificiations() {
    const response = await Axios.get(`${apiAddress}/content/getCertifications`);
    setProductCerts(response.data);
  }

  useEffect(() => {
    getProductCertificiations();
  }, []);

  return (
    <>
      <div className='inner-padding advanced-search-container'>
        <div className='search-section-container'>
          {searchSections
            ?.filter((i) => i.name !== 'Certification')
            ?.map((section, index) => (
              <div
                key={index}
                className='advanced-search-row'
              >
                <div className='advanced-search-div-products'>
                  <h3 className='advanced-search-section'>{section.name}:</h3>
                </div>
                <div className='advanced-search-div-products'>
                  <>
                    <div
                      role='button'
                      aria-label='Filter By'
                      tabIndex={0}
                      className={`login-input-holder selection filter-dropdown ${
                        inFilter(section).length > 0 ? 'filter-active' : ''
                      }`}
                      onClick={() => changeFilterOpen(section.name)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          changeFilterOpen(section.name);
                        }
                      }}
                    >
                      {inFilter(section).length > 1 ?
                        `${inFilter(section).length} selected`
                      : inFilter(section).length > 0 ?
                        inFilter(section)
                      : 'Filter By'}
                      {filterOpen === section.name ?
                        <BiChevronUp
                          className={`laminate-select-down laminates-svg ${
                            inFilter(section).length > 0 ? 'filter-active' : ''
                          }`}
                        />
                      : <BiChevronDown
                          className={`laminate-select-down laminates-svg ${
                            inFilter(section).length > 0 ? 'filter-active' : ''
                          }`}
                        />
                      }
                    </div>
                    {filterOpen === section.name && (
                      <div className='filter-search-dropdown'>
                        {searchOptions?.map((option, index) => (
                          <div
                            key={index}
                            className='div-dropdown'
                          >
                            <input
                              type='checkbox'
                              checked={searchFilters?.some(
                                (item) => item.option === option && item.section === section.name
                              )}
                              onChange={() => handleSearchChange(option, section.name)}
                              style={{ cursor: 'pointer' }}
                            />

                            <div
                              role='button'
                              aria-label={option}
                              tabIndex={0}
                              className='filter-dropdown-text'
                              onClick={() => handleSearchChange(option, section.name)}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleSearchChange(option, section.name);
                                }
                              }}
                            >
                              {option}
                            </div>
                          </div>
                        ))}
                        <div className='div-dropdown'></div>
                      </div>
                    )}
                  </>
                  <input
                    className={`advanced-search-input-products ${
                      inTextInput(section)?.length > 0 ? 'input-active' : ''
                    }`}
                    placeholder={section.name}
                    value={inTextInput(section) || ''}
                    onChange={(e) => handleSearchTextChange(section, e.target.value)}
                  ></input>
                </div>
              </div>
            ))}
          {searchSections
            ?.filter((i) => i.name === 'Certification')
            ?.map((section, index) => (
              <div
                key={index}
                className='advanced-search-row'
              >
                <div className='advanced-search-div-products'>
                  <h3 className='advanced-search-section'>{section.name}:</h3>
                </div>
                <div
                  style={{ height: '3rem' }}
                  onMouseLeave={() => setFilterOpen()}
                >
                  <input
                    className={`advanced-search-input-products ${
                      (
                        searchTextObjects.find((item) => item.section === section.name)?.text
                          .length > 0
                      ) ?
                        'active-cert'
                      : ''
                    }`}
                    placeholder={section.name}
                    onClick={() => setFilterOpen(section.name)}
                    value={inTextInput(section) || searchCerts}
                    onChange={(e) => {
                      handleSearchTextChange(section, e.target.value);
                      setSearchCerts(e.target.value);
                    }}
                  ></input>
                  {filterOpen === section.name && (
                    <div className='filter-search-dropdown cert'>
                      {productCerts
                        ?.filter((cert) =>
                          cert.certification
                            ?.toString()
                            ?.toLowerCase()
                            ?.includes(searchCerts?.toLowerCase())
                        )
                        ?.map((cert, index) => (
                          <div
                            role='button'
                            aria-label={cert.certification}
                            tabIndex={0}
                            key={index}
                            className='div-dropdown'
                            onClick={() => {
                              handleSearchTextChange(section, cert.certification);
                              setFilterOpen('');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleSearchTextChange(section, cert.certification);
                                setFilterOpen('');
                              }
                            }}
                          >
                            <div>{cert.certification}</div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className='error-search-div'>
          <div className='error-msg-text search'>{errMsg ? `${errMsg}` : ''}</div>
          <div className='search-buttons-div'>
            <button
              className='search-button clicker'
              onClick={() => {
                setSearchFilters([{ section: 'Certification', option: 'Exact Match' }]);
                setSearchTextObjects([]);
                setErrMsg('');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchFilters([{ section: 'Certification', option: 'Exact Match' }]);
                  setSearchTextObjects([]);
                  setErrMsg('');
                }
              }}
            >
              Clear
            </button>
            <button
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              className='search-button clicker'
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdvancedSearch;
