/* eslint-disable no-console */
import { useState } from 'react';
import Axios from 'axios';
import saveAs from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
import useAxiosJWT from '../hooks/useAxiosJWT';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const ApiTests = ({ cart }) => {
  const [nextCursor, setNextCursor] = useState([]);
  const [searchParam, setSearchParam] = useState([]);
  const [cursorCount, setCursorCount] = useState(1);
  const axiosJWT = useAxiosJWT();
  function getFreightHistory() {
    axiosJWT.post('NPS/getQuoteHistory').then(() => {});
  }

  function savecarttoDB() {
    axiosJWT
      .post('NPS/saveCart', {
        cart: cart,
      })
      .then(() => {});
  }

  function getCart() {
    axiosJWT.get('NPS/getcart').then((response) => {
      console.log(response.data);
    });
  }

  function getProductData(modelNum) {
    Axios.post(`${apiAddress}/products/getFullProductList`, {
      sku: modelNum,
    }).then((response) => {
      console.log(response.data);
    });
  }

  function getOptionsbySKU(modelNum) {
    Axios.post(`${apiAddress}/products/getOptionsbySKU`, {
      sku: modelNum || 'SLT4-2460C',
    }).then((response) => {
      console.log(response.data);
    });
  }

  function getfqOptionsbySKU(modelNum) {
    Axios.post(`${apiAddress}/products/getfreightQuoteConfig`, {
      sku: modelNum || 'MTFB12-MDPEPC',
    }).then((response) => {
      console.log(response.data);
    });
  }

  function searchTest(searchText) {
    Axios.post(`${apiAddress}/products/search`, {
      searchText: searchText,
    }).then((response) => {
      console.log(response.data);
    });
  }

  function sendEmail() {
    Axios.post(`${apiAddress}/sendemail`, {
      email: 'Hi, this is a test',
    }).then((response) => {
      console.log(response.data);
    });
  }
  function getCDNLinks() {
    Axios.get(`${apiAddress}/products/getImageLinks`).then((response) => {
      console.log(response.data);
    });
  }

  function getCloudinaryAssets() {
    console.log(nextCursor);
    Axios.post(`${apiAddress}/assets/getAssetData`, {
      nextCursor: nextCursor,
      cursorCount: cursorCount,
    }).then((response) => {
      setNextCursor(response.data.next_cursor);
      setCursorCount(cursorCount + 1);
      console.log(response.data);
    });
  }
  function getCloudinaryAssetDetails() {
    console.log(nextCursor);

    Axios.post(`${apiAddress}/assets/getAssetDetails`).then((response) => {
      console.log(response.data);
    });
  }

  function getUsersForAccount(account) {
    Axios.post(`${apiAddress}/account/getUsersForAccount`, {
      accountNum: account,
    }).then((response) => {
      console.log(response.data);
    });
  }

  function updateCloudinaryImages() {
    Axios.post(`${apiAddress}/cloudinary/updateCloudinaryImages`).then(() => {});
  }

  function updateEnvCloudinaryImages() {
    Axios.post(`${apiAddress}/cloudinary/updateEnvCloudinaryImages`).then((response) => {
      console.log(response.data);
    });
  }

  function skuLookup(sku) {
    Axios.post(`${apiAddress}/products/websiteSkuLookup`, {
      sku: sku || 'MTFB12-MDPEPC',
    }).then((response) => {
      console.log(response.data);
    });
  }

  function updateSiteMap() {
    axiosJWT.get(`${apiAddress}/sitemap/update`).then((response) => {
      console.log(response.data);
    });
  }

  function getOrdersForUser(id) {
    Axios.post(`${apiAddress}/orders/getOrdersForUser`, {
      userID: id,
    })
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function getPricingForItems() {
    try {
      // const response = await axiosJWT.get(
      //   `${apiAddress}/products/getItemListForPricing`
      // );
      // var itemList = response.data;

      await axiosJWT.post(`${apiAddress}/products/accountPricingTest`);

      return;
    } catch (error) {
      console.error('Error fetching customer prices for cart:', error);
    }
  }

  function getAllInclusive() {
    axiosJWT.get(`${apiAddress}/products/getAllInclusive`).then((response) => {
      ExportToExcel(response.data, 'AllInclusive');
    });
  }

  function getAllInclusiveGrouped() {
    axiosJWT.get(`${apiAddress}/products/getAllInclusiveGrouped`).then((response) => {
      ExportToExcel(response.data, 'AllInclusiveGroups');
    });
  }

  function getAllInclusiveQuickship() {
    axiosJWT.get(`${apiAddress}/products/getAllInclusiveQuickship`).then((response) => {
      ExportToExcel(response.data, 'AllInclusiveQuickship');
    });
  }

  function getAllInclusiveDownload() {
    axiosJWT.get(`${apiAddress}/products/allInclusiveDownload`).then((response) => {
      ExportToExcel(response.data, 'AllInclusiveDownload');
    });
  }

  function ExportToExcel(dataObjects, fileName) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(dataObjects);
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([eb], { type: EXCEL_TYPE });
    saveAs(blob, fileName + EXCEL_EXTENSION);
  }

  async function getImageAndDescription() {
    axiosJWT
      .post(`${apiAddress}/products/getImageAndDescription`, {
        sku: 'HDT3-3036HGY',
      })
      .then((response) => {
        console.log(response.data, 2);
      });
  }

  function getsAllCloudinaryImages() {
    Axios.get(`${apiAddress}/cloudinary/getAllCloudinaryImages`).then((response) => {
      ExportToExcel(response.data, 'cloudinaryImages');
      console.log(response.data);
    });
  }

  function getTabletopItemsInStock() {
    Axios.get(`${apiAddress}/stockItems/getTabletopItemsInStock`).then((response) => {
      ExportToExcel(response.data, 'tabltop items');
      console.log(response.data);
    });
  }

  function getImages() {
    Axios.get(`${apiAddress}/cloudinary/getImages`).then((response) => {
      console.log(response.data);
      ExportToExcel(
        response.data?.resources?.map((i) => ({ secure_url: i.secure_url })),
        'images'
      );
    });
  }

  return (
    <>
      <div>
        <button onClick={() => getCloudinaryAssets()}>Get Assets from Cloudinary</button>
        <button onClick={() => getCloudinaryAssets(nextCursor)}>Get Next Asset List</button>
        <button onClick={() => getOptionsbySKU('6218HB-10')}>getOptionsbySKU</button>
        <button onClick={() => getProductData('CT Series')}>Get 9200 Data</button>
        <button onClick={() => sendEmail()}>Send Email</button>
        <button onClick={() => getCDNLinks()}>Get CDN Links</button>
        <button onClick={() => getCloudinaryAssetDetails()}>Get Single Asset Details</button>
        <button onClick={() => getfqOptionsbySKU('MTFB12-MDPEPC')}>Get FQ Data</button>
        <button onClick={() => getFreightHistory()}>Get FQ History</button>
        <button onClick={() => savecarttoDB()}>Save cart to DB</button>
        <button onClick={() => getCart()}>Get cart</button>
        <button onClick={() => getUsersForAccount(1306)}>Get users for account</button>
        <button onClick={() => updateCloudinaryImages()}>Get all cloudinary images</button>
        <button onClick={() => updateEnvCloudinaryImages()}>update env cloudinary images</button>
        <button onClick={() => skuLookup('MTFB')}>Sku Lookup</button>
        <button onClick={() => getOrdersForUser(1920)}>Get Orders For User</button>
        <button>Worked</button>
        <button onClick={() => updateSiteMap()}>update sitemap</button>
        <button onClick={() => getPricingForItems()}>pricing test</button>
        <button onClick={() => getAllInclusive()}>get all inclusive</button>
        <button onClick={() => getAllInclusiveGrouped()}>get all inclusive grouped</button>
        <button onClick={() => getAllInclusiveQuickship()}>get all inclusive quickship</button>
        <button onClick={() => getAllInclusiveDownload()}>get all inclusive download</button>
        <button onClick={() => getImageAndDescription()}>get image and desc</button>
        <button onClick={() => getsAllCloudinaryImages()}>get all cloudinary images</button>
        <button onClick={() => getTabletopItemsInStock()}>get tabletop items in stock</button>
        <button onClick={() => getImages()}>get Images</button>
      </div>
      <div>
        <input
          type='input'
          onChange={(e) => {
            setSearchParam(e.target.value);
          }}
        ></input>
        <button onClick={() => searchTest(searchParam)}>Search</button>
      </div>
      {/* <button onClick={handleDeleteOldFreightQuotes}>
        deleteOldFreightQuotes
      </button> */}
    </>
  );
};

export default ApiTests;
