import { useLocation } from 'react-router-dom';
import EditTemplate from '../EditorPortal/EditTemplate';

const RouteRequests = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Convert searchParams to string
  const queryString = searchParams.toString();
  let decoded;

  if (queryString) {
    decoded = decodeURIComponent(queryString)?.substring(queryString?.indexOf('=') + 1);
  }

  const textDesc =
    '  All users that are associated with an account that does not have an admin, and are requesting access to a page. Accepting their request will give then them permission needed for that route request.';
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'username',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'username',
    },
    {
      type: 'text',
      name: 'dealer',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'dealer',
    },
    {
      type: 'text',
      name: 'account_num',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'account_num',
    },
    {
      type: 'text',
      name: 'Price Level',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'priceLevel',
    },
    {
      type: 'text',
      name: 'request',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'pending_requests',
      fieldName: 'request',
    },
    {
      type: 'text',
      name: 'permission',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'route_permissions',
      fieldName: 'permission_role',
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'getRouteRequests'}
      title={'Route Requests'}
      table={'pending_requests'}
      exportAvailable={false}
      importBatch={false}
      exportTemplate={false}
      importUpdate={false}
      addHandlerIncoming={null}
      searchInput={decoded}
      textDesc={textDesc}
    />
  );
};

export default RouteRequests;
