import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import EnvironmentPopUp from '../../Components/Environments/EnvironmentPopUp';
import EnvPopup from '../../Components/Shared/EnvPopup';
import '../../CSS/Environments.css';
import EnvImages from './EnvImages';
import FilterButtons from './FilterButtons';
import '../../CSS/MobileApp/M_Environments.css';
import useGeneralImages from '../../hooks/useGeneralImages';

const Environments = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const { generalImages } = useGeneralImages();
  const banner =
    !mobileScreen ?
      generalImages.find((i) => i.publicId === 'Environments_jtmep3')?.imageLink
    : generalImages.find((i) => i.publicId === 'ENVIRONMENTS_-_Mobile_banner_mlzerb')?.imageLink;
  // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  useEffect(() => {
    document.title = `Environments - National Public Seating`;
  }, []);
  const [showPopup, setShowPopup] = useState('');
  const [popElement, setPopElement] = useState('');
  const location = useLocation();
  const [envFilter, setEnvFilter] = useState(location.state?.selectedEnv || 'all');
  const [filterList, setFilterList] = useState();
  const [imageList, setImageList] = useState();
  const [allImages, setAllImages] = useState();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);

  function clickHandler(item) {
    if (mobileScreen) {
      setShowPopup('mobile');
    } else {
      setShowPopup('desktop');
    }
    setPopElement(
      <EnvironmentPopUp
        data={item}
        filter={envFilter}
        setShow={setShowPopup}
      />
    );
  }

  function getData() {
    Axios.get(`${apiAddress}/content/getEnvironments`).then((response) => {
      setImageList(response.data.envList);
      setAllImages(response.data.envList);
    });
  }

  function getFilterList() {
    var holderSet = new Set();
    allImages?.map((i) => {
      i.category.map((i) => holderSet.add(i));
    });
    setFilterList(Array.from(holderSet));
  }

  function filterImageList() {
    if (envFilter === 'all') {
      setImageList(allImages);
    } else {
      setImageList(
        allImages?.filter((i) => {
          return i.category.some((i) => i.toLowerCase() === envFilter.toLowerCase());
        })
      );
    }
  }

  useEffect(() => {
    getData();
    if (searchParamsUse.filter) {
      setEnvFilter(searchParamsUse.filter.toLowerCase());
    } else {
      setEnvFilter('all');
    }
  }, []);

  useEffect(() => {
    if (searchParamsUse.filter) {
      setEnvFilter(searchParamsUse.filter.toLowerCase());
    } else {
      setEnvFilter('all');
    }
  }, [searchParamsUse]);

  useEffect(() => {
    getFilterList();
  }, [allImages]);

  useEffect(() => {
    filterImageList();
  }, [envFilter, allImages]);

  useEffect(() => {
    // when the image changes ensure it scrolls to the top
    if (showPopup === 'mobile') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [showPopup]);

  return (
    <div className='environments'>
      <img
        src={banner}
        className='banner'
        style={{ marginTop: '2rem' }}
        alt='Environment Banner'
      ></img>
      <div className='inner-padding'>
        {showPopup !== 'mobile' && (
          <>
            <div className='envir-title-div '>
              <h2>View by environment</h2>
              <div className='underline'></div>
              <h3>Get inspired with our gallery of spaces furnished with our product!</h3>
            </div>
            <FilterButtons
              list={filterList}
              envFilter={envFilter}
              setEnvFilter={setEnvFilter}
            />
            <EnvImages
              imageList={imageList}
              envFilter={envFilter}
              clickHandler={clickHandler}
            />
          </>
        )}
        {showPopup === 'desktop' && (
          <EnvPopup
            element={popElement}
            setShow={setShowPopup}
          />
        )}
        {showPopup === 'mobile' && <div className='mobile-environment'>{popElement}</div>}
      </div>
    </div>
  );
};

export default Environments;
