import Axios from 'axios';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SlArrowLeft } from 'react-icons/sl';
import { useSelector } from 'react-redux';
import AddFinishes from '../../Components/FreightCart/AddFinishes';
import AddtoCart from '../../Components/FreightCart/AddtoCart';
import Cart from '../../Components/FreightCart/Cart';
import CartPrice from '../../Components/FreightCart/CartPrice';
import MobileFreightQuote from '../../Components/FreightCart/MobileFreightQuote';
import Options from '../../Components/FreightCart/Options';
import QuoteImage from '../../Components/FreightCart/QuoteImage';
import { useCart } from '../../context/CartContext';
import '../../CSS/FreightCart.css';
import '../../CSS/MobileApp/M_FreightCart.css';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import useLocalStorage from '../../hooks/useLocalStorage';
import FreightQuestions from './FreightQuestions';

const FreightCart = () => {
  const { cart, addToCart, updateQty, removeFromCart, notificationData } = useCart();
  const { auth } = useAuth();
  const [modelNum, setModelNum] = useState('');
  const [quantity, setQuantity] = useState('');
  const [zipCode, setZipCode] = useLocalStorage('zipCode', '');
  const [refNum, setRefNum] = useState('');
  const [quoteLoading, setQuoteLoading] = useState(false);
  const [insideDelivery, setInsideDelivery] = useLocalStorage('insideDelivery', false);
  const [liftgate, setLiftgate] = useLocalStorage('liftgate', false);
  const [limitedAccess, setLimitedAccess] = useLocalStorage('limitedAccess', false);
  const [residentialDelivery, setResidentialDelivery] = useLocalStorage(
    'residentialDelivery',
    'residentialDelivery',
    false
  );
  const [accesorialString, setAccesorialString] = useState('NNNNN');
  const [quote, setQuote] = useState();
  const [skuList, setSkuList] = useState();
  const axiosJWT = useAxiosJWT();
  const [showFreightForm, setShowFreightForm] = useState(false);
  const [showPriceForm, setShowPriceForm] = useState(false);
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const [finishesNeeded, setFinishesNeeded] = useState(false);
  const [quoteError, setQuoteError] = useState(false);
  const [searchVal, setSearchVal] = useState();
  const [total, setTotal] = useState();
  const [hover, setHover] = useState();
  const [requested, setRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [mobileCurrentPage, setMobileCurrentPage] = useState('pageOne');
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const [addClicked, setAddClicked] = useState(false);
  const [header, setHeader] = useState('cart');
  const [notice, setNotice] = useState('');
  const [allowedAccess, setAllowedAccess] = useState(false);

  const dateValidUntil = new Date(); // Now
  dateValidUntil.setDate(dateValidUntil.getDate() + 30);

  function getSkuList() {
    Axios.get(`${apiAddress}/products/getItemList`).then((response) => {
      setSkuList(response.data.filteredArray);
    });
  }

  function getFinishes() {
    if (modelNum && quantity) {
      Axios.post(`${apiAddress}/products/getfreightQuoteConfig`, {
        sku: modelNum.ModelNum,
      }).then((response) => {
        setFinishesNeeded(response.data.itemConfig);
        return response.data.itemConfig;
      });
    }
  }

  function getFreight() {
    if (cart?.length) {
      setQuote();
      setQuoteError();
      setQuoteLoading(true);
      axiosJWT
        .post(`${apiAddress}/NPS/freightCart`, {
          zipCode: zipCode,
          options: accesorialString,
          itemQty: cart.length,
          items: [...cart],
          refNum: refNum,
        })
        .then((response) => {
          setQuote(response.data);
          setQuoteLoading(false);
        })
        .catch((err) => {
          setQuoteError(err.response.data);
          setQuoteLoading(false);
        });
    }
  }

  function resetValues() {
    setModelNum('');
    setSearchVal('');
    setModelNum('');
    setSearchVal('');
    setFinishesNeeded(false);
    setQuantity('');
    setQuantity('');
  }

  function calculateTotal() {
    const totalPrice = cart.reduce((acc, obj) => {
      const price = parseFloat(obj.price);
      if (!isNaN(price)) {
        acc += price * obj.qty;
      }
      return acc;
    }, 0);
    setTotal(totalPrice);

    if (cart.every((i) => isNaN(i.price))) {
      setTotal('Unavailable');
    }
  }

  function resetMobilePage() {
    switch (mobileCurrentPage) {
      case 'pageThree':
        setMobileCurrentPage('pageTwo');
        break;
      default:
        setMobileCurrentPage('pageOne');
    }
  }

  function checkAllowedAccess() {
    setAllowedAccess(
      auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) &&
        !notificationData?.some((i) => i.Path === 'pricing')
    );
  }

  async function requestAccess() {
    const response = await axiosJWT.post(
      `auth/accessRequest`,
      JSON.stringify({
        user: auth?.rep,
        pageRequest: 'product pricing',
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );
    if (response.status === 200) {
      if (response.data.message) {
        setRequested(false);
        setErrorMessage(response.data.message);
      } else {
        setRequested(true);
      }
    }
  }

  useEffect(() => {
    document.title = `Cart - National Public Seating`;
  }, []);

  useEffect(() => {
    getSkuList();
  }, []);

  useEffect(() => {
    if (!addClicked) {
      resetValues();
    }
    setAddClicked(false);
  }, [mobileCurrentPage]);

  useEffect(() => {
    let tempString = 'N';
    tempString =
      tempString +
      (insideDelivery ? 'Y' : 'N') +
      (liftgate ? 'Y' : 'N') +
      (limitedAccess ? 'Y' : 'N') +
      (residentialDelivery ? 'Y' : 'N');

    setAccesorialString(tempString);
  }, [insideDelivery, liftgate, limitedAccess, residentialDelivery]);

  useEffect(() => {
    if (cart.length === 0) {
      setMobileCurrentPage('pageOne');
      // when the cart is empty have it scroll to top
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    calculateTotal();
  }, [cart]);

  useEffect(() => {
    if (!quantity) {
      setQuantity('');
    }
  }, [quantity]);

  //for mobile- easy scroll to top when page changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [mobileCurrentPage]);

  useEffect(() => {
    if (notice !== 'Sending email...') {
      const timer = setTimeout(() => {
        setNotice();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notice]);

  useEffect(() => {
    checkAllowedAccess();
  }, [auth]);

  return (
    <div className='freight-cart'>
      <div className='cartHeader'>
        {isMobile && mobileCurrentPage !== 'pageOne' ?
          <div className='mobile-arrow'>
            <SlArrowLeft
              onClick={resetMobilePage}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  resetMobilePage();
                }
              }}
            />
            <h2 className={header !== 'cart' ? 'price-quote' : ''}>{header}</h2>
          </div>
        : <h2>cart</h2>}
        <div className='underline'></div>
      </div>

      {(!mobileScreen || mobileCurrentPage === 'pageOne') && (
        <>
          <AddtoCart
            modelNum={modelNum}
            setModelNum={setModelNum}
            addToCart={addToCart}
            quantity={quantity}
            setQuantity={setQuantity}
            skuList={skuList}
            getFinishes={getFinishes}
            searchVal={searchVal}
            setSearchVal={setSearchVal}
            resetValues={resetValues}
            setAddClicked={setAddClicked}
          />
          {finishesNeeded && finishesNeeded?.length ?
            <AddFinishes
              finishesNeeded={finishesNeeded}
              addToCart={addToCart}
              setFinishesNeeded={setFinishesNeeded}
              product={modelNum}
              quantity={quantity}
              setModelNum={setModelNum}
              setQuantity={setQuantity}
              setSearchVal={setSearchVal}
              resetValues={resetValues}
            />
          : ''}
          <Cart
            cart={cart}
            updateQty={updateQty}
            removeFromCart={removeFromCart}
            allowedAccess={allowedAccess}
          />
          {cart?.length && allowedAccess ?
            <div className='cart-headers grandTotal-div'>
              <div className='grandTotal-title'>Grand Total:</div>
              <div className={`${total ? 'grandTotal-value' : 'loading-price'}`}>
                {total?.toLocaleString(undefined, {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                <span
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  {cart?.some((i) => i.price === 'Custom Pricing') ? '*' : ''}
                </span>
                {hover ?
                  total === 'Unavailable' ?
                    <div
                      style={{ marginLeft: '3.5rem' }}
                      className='hover-price'
                    >
                      Pricing not available. Request custom quote below.
                    </div>
                  : <div className='hover-price'>
                      Pricing not complete, subject to custom pricing.
                    </div>

                : ''}
              </div>
            </div>
          : ''}
          <CartPrice
            requested={requested}
            errorMessage={errorMessage}
            setMobileFreight={setMobileCurrentPage}
            requestAccess={requestAccess}
            mobileCurrentPage={mobileCurrentPage}
            setMobileCurrentPage={setMobileCurrentPage}
            setNotice={setNotice}
            allowedAccess={allowedAccess}
          />
        </>
      )}

      <div className={!mobileScreen ? 'freight-container' : ''}>
        {(!mobileScreen || mobileCurrentPage === 'pageTwo') && (
          <div className={!mobileScreen ? 'left_section' : 'mobile-freight-options'}>
            {mobileScreen && mobileCurrentPage === 'pageTwo' ?
              <MobileFreightQuote />
            : null}
            <Options
              insideDelivery={insideDelivery}
              setInsideDelivery={setInsideDelivery}
              liftgate={liftgate}
              setLiftgate={setLiftgate}
              limitedAccess={limitedAccess}
              setLimitedAccess={setLimitedAccess}
              residentialDelivery={residentialDelivery}
              setResidentialDelivery={setResidentialDelivery}
              zipCode={zipCode}
              setZipCode={setZipCode}
              refNum={refNum}
              setRefNum={setRefNum}
              getFreight={getFreight}
              quoteLoading={quoteLoading}
              setMobileCurrentPage={setMobileCurrentPage}
            />
          </div>
        )}

        {(!mobileScreen || mobileCurrentPage === 'pageThree') && (
          <>
            <QuoteImage
              quote={quote}
              quoteLoading={quoteLoading}
              quoteError={quoteError}
              setNotice={setNotice}
            />
          </>
        )}
      </div>
      <FreightQuestions
        setShowFreightForm={setShowFreightForm}
        setShowPriceForm={setShowPriceForm}
        showFreightForm={showFreightForm}
        showPriceForm={showPriceForm}
        quoteError={quoteError}
        mobileCurrentPage={mobileCurrentPage}
        setMobileCurrentPage={setMobileCurrentPage}
        setHeader={setHeader}
        cart={cart}
        setNotice={setNotice}
      />
      {notice ?
        <div className='notice'>{notice}</div>
      : ''}
    </div>
  );
};

export default FreightCart;
