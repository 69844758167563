import { useEffect, useState } from 'react';
import { HiOutlineDocumentDownload, HiOutlineExclamation } from 'react-icons/hi';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import FullPageLoading from '../Shared/FullPageLoading';
import useExportToExcel from '../Shared/useExportToExcel';
import ItemsTable from './ItemsTable';
import TableAddNew from './TableAddNew';
import ReusablePopup from '../NPSportal/ReusablePopup';
import OrderInventoryPopup from './OrderInventoryPopup';
import { BsExclamationDiamond, BsExclamationTriangleFill } from 'react-icons/bs';
import { HiOutlineExclamationTriangle } from 'react-icons/hi2';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const TableView = () => {
  const { tableName } = useParams();
  const completeTableName = decodeURIComponent(tableName)?.replace(/ /g, '');
  const ExportToExcel = useExportToExcel();
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [columns, setColumns] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [notification, setNotification] = useState();
  const [allowAdd, setAllowAdd] = useState();
  const [actionData, setActionData] = useState();
  const [filtered, setFiltered] = useState();
  const [hover, setHover] = useState(false);

  function getColumns() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getViewTableColumns`, {
        tableName: decodeURIComponent(tableName),
      })
      .then((response) => {
        setColumns(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/get${completeTableName}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function handleExport() {
    axiosJWT
      .get(`${apiAddress}/itemTable/get${completeTableName}`)
      .then((response) => {
        ExportToExcel(
          {
            data: response.data,
          },
          tableName
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function checkTableForAdd() {
    if (columns[0]?.table_title?.toLowerCase()?.replace(' ', '_') === columns[0]?.db_table_name) {
      setAllowAdd(true);
    } else {
      setAllowAdd(false);
    }
  }

  useEffect(() => {
    if (tableName) {
      getColumns();
      getData();
    }
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (columns) {
      checkTableForAdd();
    }
  }, [columns]);

  return (
    <>
      {!loading && data && columns ?
        <>
          <div className='content-div'>
            <>
              <div className={`in-stock-table-header`}>
                <div
                  style={{ alignItems: 'end' }}
                  className='basic-flex'
                >
                  <h2>{tableName}</h2>
                </div>
                <div>
                  {completeTableName === 'ItemInventory' && (
                    <AiOutlineExclamationCircle
                      style={{
                        cursor: 'pointer',
                        marginRight: '10px',
                        color: filtered ? 'red' : '',
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      onClick={() => {
                        if (!filtered) {
                          setFiltered(data?.filter((i) => i.available_now < 0));
                        } else {
                          setFiltered();
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (!filtered) {
                            setFiltered(data?.filter((i) => i.available_now < 0));
                          } else {
                            setFiltered();
                          }
                        }
                      }}
                    />
                  )}
                  {allowAdd ?
                    <MdFormatListBulletedAdd
                      style={{
                        cursor: 'pointer',
                        marginRight: '10px',
                      }}
                      onClick={() => setOpenAdd(true)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setOpenAdd(true);
                        }
                      }}
                    />
                  : ''}

                  <HiOutlineDocumentDownload
                    style={{
                      cursor: 'pointer',
                    }}
                    onClick={() => handleExport()}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleExport();
                      }
                    }}
                  />
                  <div
                    className={`${!filtered && hover ? 'color-swatch-selection-hover tiny' : 'display-none'}`}
                  >
                    Click to view all items that have an on hand quantity issue.
                  </div>
                  <div
                    className={`${filtered && hover ? 'color-swatch-selection-hover tiny' : 'display-none'}`}
                  >
                    Click to view complete list of items.
                  </div>
                </div>
              </div>
            </>
            <ItemsTable
              getData={getData}
              tableName={tableName}
              tableColumns={columns}
              tableRows={filtered ? filtered : data}
              allowAdd={allowAdd}
              setNotification={setNotification}
              setActionData={setActionData}
            />
          </div>
        </>
      : loading ?
        <FullPageLoading />
      : ''}
      {openAdd && columns[0] ?
        <TableAddNew
          columns={columns}
          getData={getData}
          setOpenAdd={setOpenAdd}
          setNotification={setNotification}
        />
      : ''}
      {actionData && actionData?.popup && (
        <ReusablePopup
          customStyle={'custom-large-popup'}
          open={actionData}
          setOpen={setActionData}
          title={`${actionData?.row?.sku} (${actionData?.row?.location})`}
          innerComponent={
            <OrderInventoryPopup
              setActionData={setActionData}
              incomingData={actionData}
            />
          }
        />
      )}
      {notification ?
        <div
          style={{
            fontSize: '16px',
            top: '47.7rem',
            marginLeft: '4.8rem',
            width: '520px',
          }}
          className='notice'
        >
          {notification}
        </div>
      : ''}
    </>
  );
};

export default TableView;
