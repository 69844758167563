import Axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import '../../CSS/ServicePortal.css';
import ClaimButton from '../../Components/Service Portal/ClaimButton';
import ClaimForm from '../../Components/Service Portal/ClaimForm';
import GeneralFeedback from '../../Components/Service Portal/GeneralFeedback';
import useGeneralImages from '../../hooks/useGeneralImages';

const ServicePortal = () => {
  const { generalImages } = useGeneralImages();
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const ref = useRef(null);
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState();
  const [allSelectButton, setAllSelectButton] = useState([]);
  const [buttonTitles, setButtonTitles] = useState();
  const [showForm, setShowForm] = useState();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  const banner = generalImages.find((i) => i.publicId === 'customerPortal_bm9dtc')?.imageLink;

  function navigateButton(buttonTitle) {
    navigate(`/contact?category=${buttonTitle}`, { replace: true });
  }

  function navigateForm(formTitle) {
    if (formTitle) {
      navigate({
        pathname: `/contact`,
        search: `?${createSearchParams(
          {
            category: searchParamsUse.category,
            form: formTitle,
          },
          { replace: true }
        )}`,
      });
    } else {
      navigate({
        pathname: `/contact`,
        search: `?${createSearchParams(
          {
            category: searchParamsUse.category,
          },
          { replace: true }
        )}`,
      });
    }
  }

  function scrollOnClick() {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function getAboutServicePortal() {
    Axios.get(`${apiAddress}/content/getAboutServicePortal`)
      .then((response) => {
        setButtonTitles(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function getAllSelectedButtons() {
    Axios.get(`${apiAddress}/content/getSelectButton`)
      .then((response) => {
        setAllSelectButton(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    document.title = `Contact - National Public Seating`;
  }, []);

  useEffect(() => {
    if (searchParamsUse.category) {
      setSelectedButton(searchParamsUse.category);
    } else if (!searchParamsUse.category) {
      setSelectedButton();
    }
    if (searchParamsUse.form) {
      setShowForm(searchParamsUse.form);
    } else if (!searchParamsUse.form) {
      setShowForm();
    }
  }, [searchParamsUse]);

  useEffect(() => {
    getAllSelectedButtons();
    getAboutServicePortal();
  }, []);

  return (
    <div className='servicePortal'>
      <div className='banner'>
        <img
          src={banner}
          alt='service portal banner'
        ></img>
      </div>
      <div
        style={{ marginTop: '-30rem' }}
        className='inner-padding'
      >
        <div className='buttons'>
          {buttonTitles?.map((i) => {
            return (
              <ClaimButton
                key={i.title}
                i={i}
                selectedButton={selectedButton}
                setSelectedButton={navigateButton}
                setShowForm={navigateForm}
                scrollOnClick={scrollOnClick}
              />
            );
          })}
        </div>
        <div
          ref={ref}
          style={{ position: 'absolute', top: '52rem' }}
          id='formSection'
        ></div>
        {selectedButton && !showForm && selectedButton !== 'contact-us' ?
          <div className='form'>
            <h2>{selectedButton.replace(/-/g, ' ')} Options</h2>
            {allSelectButton
              .filter((i) => i.select_button === selectedButton)
              ?.map((i, index) => {
                return (
                  <div
                    role='button'
                    aria-label={i.title}
                    tabIndex={0}
                    key={index}
                    className='option'
                    onClick={() => {
                      i.navigate_form === 1 ? navigateForm(i.navigate) : navigate(i.navigate);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        i.navigate_form === 1 ? navigateForm(i.navigate) : navigate(i.navigate);
                      }
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div className='vertical-line'></div>
                      <img
                        alt={i.title}
                        src={i.image}
                      ></img>
                      <div className='title'>{i.title}</div>
                    </div>
                    <AiOutlineRight />
                  </div>
                );
              })}
          </div>
        : showForm ?
          <ClaimForm
            setShowForm={navigateForm}
            showForm={showForm}
            selectedButton={selectedButton}
          />
        : selectedButton === 'contact-us' ?
          <GeneralFeedback />
        : showForm === 'something-else' ?
          ''
        : ''}
      </div>
    </div>
  );
};

export default ServicePortal;
