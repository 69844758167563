import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import { configureStore } from '@reduxjs/toolkit';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import ScrollToTop from './Components/Shared/ScrollToTop';
import { AuthProvider } from './context/AuthProvider';
import { UserProvider } from './context/UserProvider';
import isMobileReducer from './ReduxSlices/isMobile';
import productStateReducer from './ReduxSlices/productState';
import reportWebVitals from './reportWebVitals';
import './wdyr';

const store = configureStore({
  reducer: {
    isMobile: isMobileReducer,
    productState: productStateReducer,
  },
});

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
        <UserProvider>
          <ScrollToTop />
          <Routes>
            <Route
              path='/*'
              element={<App />}
            />
          </Routes>
        </UserProvider>
      </AuthProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function

// eslint-disable-next-line no-warning-comments
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
