import { useLocation } from 'react-router-dom';
import EditTemplate from '../EditorPortal/EditTemplate';

const DealerAccountRequests = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.toString();
  let decoded;

  if (queryString) {
    decoded = decodeURIComponent(queryString)?.substring(queryString?.indexOf('=') + 1);
  }

  const textDesc =
    'All users that are requesting a page that is linked to account permissions and they are not associated with any account. Manage their permissions here when adding them to an account.';
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'username',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'username',
    },
    {
      type: 'text',
      name: 'dealer',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'dealer',
    },
    {
      type: 'text',
      name: 'request',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'pending_requests',
      fieldName: 'request',
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'getRequestsForDealerAccount'}
      title={'Dealer Account Requests'}
      table={'pending_requests'}
      exportAvailable={false}
      importBatch={false}
      exportTemplate={false}
      importUpdate={false}
      addHandlerIncoming={null}
      searchInput={decoded}
      textDesc={textDesc}
    />
  );
};

export default DealerAccountRequests;
