import { useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import SavedQuote from '../../Components/FreightCart/SavedQuote';
import Paginator from '../../Components/Media/Paginator';
import '../../CSS/OrderTracking.css';
import '../../CSS/SavedQuotes.css';
import useAuth from '../../hooks/useAuth';
import useAxiosJWT from '../../hooks/useAxiosJWT';

// import {CartButton} from '../assets/SVG/ReactSvg/CartButton'
const SavedQuotes = () => {
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const { auth } = useAuth();
  const axiosJWT = useAxiosJWT();

  const [myQuotes, setMyQuotes] = useState();
  const [open, setOpen] = useState();
  const [filters, setFilters] = useState({
    active: false,
    expired: false,
  }); //status filters
  const [sort, setSort] = useState('Date Submitted');
  const sortOptions = ['Quote Number', 'Date Submitted', 'Total Cost', 'Status', 'Zip'];
  const [advancedSearchOption, setAdvancedSearchOption] = useState('Quote #');
  const advancedSearchOptions = ['Quote #', 'Reference #', 'Zip Code', 'User', 'Item SKU'];
  const [search, setSearch] = useState();
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(20);
  // const [openHistory, setOpenHistory] = useState(false);
  // const [openStatus, setOpenStatus] = useState(false);
  // const [trackingData, setTrackingData] = useState([]);
  // const [status, setStatus] = useState();
  // const [quoteNum, setQuoteNum] = useState('');

  useEffect(() => {
    document.title = 'Saved Quotes - National Public Seating';
  }, []);

  useEffect(() => {
    setOpen();
  }, [search]);

  useEffect(() => {
    if (
      myQuotes
        ?.filter((i) => {
          if (search && advancedSearchOption) {
            if (advancedSearchOption === 'Zip Code') {
              return i.zipcode?.includes(search);
            } else if (advancedSearchOption === 'Item SKU') {
              return i.items?.some((item) =>
                item.displaySku?.toLowerCase()?.includes(search?.toLowerCase())
              );
            } else if (advancedSearchOption === 'Reference #') {
              return i.refNum?.toLowerCase()?.includes(search.toString().toLowerCase());
            } else if (advancedSearchOption === 'Quote #') {
              return i.quoteNum?.includes(search);
            } else if (advancedSearchOption === 'User') {
              return i?.userName?.toLowerCase()?.includes(search.toString().toLowerCase());
            }
          } else {
            return true;
          }
          // return search ? i.quoteNum.includes(search) : true;
        })
        ?.filter((i) => {
          return Object.values(filters).every((el) => el === false) ?
              true
            : filters[i.status?.toLowerCase()];
        })?.length > 0
    ) {
      setLastPage(
        Math.ceil(
          myQuotes
            ?.filter((i) => {
              if (search && advancedSearchOption) {
                if (advancedSearchOption === 'Zip Code') {
                  return i.zipcode?.includes(search);
                } else if (advancedSearchOption === 'Item SKU') {
                  return i.items?.some((item) =>
                    item.displaySku?.toLowerCase()?.includes(search?.toLowerCase())
                  );
                } else if (advancedSearchOption === 'Reference #') {
                  return i.refNum?.toLowerCase()?.includes(search.toString().toLowerCase());
                } else if (advancedSearchOption === 'Quote #') {
                  return i.quoteNum?.includes(search);
                } else if (advancedSearchOption === 'User') {
                  return i?.rep?.toLowerCase()?.includes(search.toString().toLowerCase());
                }
              } else {
                return true;
              }
              // return search ? i.quoteNum.includes(search) : true;
            })
            ?.filter((i) => {
              return Object.values(filters).every((el) => el === false) ?
                  true
                : filters[i.status?.toLowerCase()];
            })?.length / show
        )
      );
    }
    setPage(0);
  }, [myQuotes, search]);

  //   useEffect(()=>{
  //     if()
  //   },[trackingData])
  // const cld = new Cloudinary({
  //     cloud: {
  //         cloudName: 'da3rom333'
  //     }
  // });

  function getQuotes() {
    axiosJWT.post(`${apiAddress}/NPS/getQuoteHistory`).then((response) => {
      setMyQuotes(response.data.quoteHistory);
    });
  }

  useEffect(() => {
    getQuotes();
  }, []);

  function handleFilterChange(i) {
    if (filters[i]) {
      setFilters({ ...filters, [i]: false });
    } else {
      setFilters({ ...filters, [i]: true });
    }
  }

  function setOpenItem(title) {
    if (open === title) {
      setOpen();
    } else {
      setOpen(title);
    }
  }

  return (
    <div
      className='track-order saved-quotes'
      style={{ width: '1215px', margin: 'auto' }}
    >
      <h1>Quotes History</h1>
      <div className='underline'></div>
      {/* <div className="quotes-search">
        <h2>Find Your Quote</h2>
        <div className="underline"></div>
        <div className="track">
          <input
            placeholder="Quote Number"
            value={quoteNum}
            onChange={(e) => {
              setQuoteNum(e.target.value);
            }}
            name="quoteNum"
            className="orderNum"
          ></input>

          <button className="track-button" onClick={findQuote}>
            Search
          </button>
        </div>
      </div> */}
      <div className='quotes-search'>
        {(
          auth?.accounts
            ?.find((i) => i.last_login === 1)
            ?.AccountRoles?.find((i) => [1100, 1200].includes(i))
        ) ?
          <h2>All Quotes:</h2>
        : <h2>My Quotes:</h2>}
        <div className='underline'></div>
        <div className='order-filters'>
          <input
            placeholder={
              advancedSearchOption === 'Reference #' ? 'Search by Reference #'
              : advancedSearchOption === 'Zip Code' ?
                'Search by Zip Code'
              : advancedSearchOption === 'Item SKU' ?
                'Search by Item SKU'
              : advancedSearchOption === 'User' ?
                'Search by User'
              : 'Search by Quote #'
            }
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></input>
          <div
            className='statusFilter'
            style={{ marginTop: '0px', marginLeft: '26rem' }}
          >
            <div
              role='button'
              aria-label='Advanced Search'
              tabIndex={0}
              className='statusTitle'
              onClick={() => {
                setOpenItem('advancedSearch');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpenItem('advancedSearch');
                }
              }}
            >
              Advanced Search
              {open === 'advancedSearch' ?
                <FiChevronUp
                  className='open'
                  onClick={() => {
                    setOpen();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen();
                    }
                  }}
                />
              : <FiChevronDown
                  className='open'
                  onClick={() => {
                    setOpenItem('advancedSearch');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpenItem('advancedSearch');
                    }
                  }}
                />
              }
            </div>
            {open === 'advancedSearch' ?
              <div className='status-filters'>
                {advancedSearchOptions
                  ?.filter((i) =>
                    (
                      auth?.accounts
                        ?.find((i) => i.last_login === 1)
                        ?.AccountRoles?.find((i) => [1100, 1200].includes(i))
                    ) ?
                      i
                    : i !== 'User'
                  )
                  .map((i, index) => {
                    return (
                      <div
                        role='button'
                        aria-label={i}
                        tabIndex={0}
                        key={index}
                        className='status-filter'
                        onClick={() => {
                          setAdvancedSearchOption(i);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setAdvancedSearchOption(i);
                          }
                        }}
                      >
                        <div
                          role='button'
                          aria-label={i}
                          tabIndex={0}
                          className='selection-box'
                          onClick={() => {
                            setAdvancedSearchOption(i);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              setAdvancedSearchOption(i);
                            }
                          }}
                        >
                          {advancedSearchOption === i ?
                            <BsCheckLg />
                          : ''}
                        </div>
                        {i}
                      </div>
                    );
                  })}
              </div>
            : ''}
          </div>

          <div
            className='statusFilter'
            style={{ marginTop: '0px', marginLeft: '48rem' }}
          >
            <div
              role='button'
              aria-label=' Filter By Status'
              tabIndex={0}
              className='statusTitle'
              onClick={() => {
                setOpenItem('status');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpenItem('status');
                }
              }}
            >
              Filter By Status
              {open === 'status' ?
                <FiChevronUp
                  className='open'
                  onClick={() => {
                    setOpen();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen();
                    }
                  }}
                />
              : <FiChevronDown
                  className='open'
                  onClick={() => {
                    setOpenItem('status');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpenItem('status');
                    }
                  }}
                />
              }
            </div>
            {open === 'status' ?
              <div className='status-filters'>
                {Object.keys(filters).map((i, index) => {
                  return (
                    <div
                      role='button'
                      aria-label={i}
                      tabIndex={0}
                      key={index}
                      className='status-filter'
                      onClick={() => {
                        handleFilterChange(i);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleFilterChange(i);
                        }
                      }}
                    >
                      <div
                        role='button'
                        aria-label={i}
                        tabIndex={0}
                        className='selection-box'
                        onClick={() => {
                          handleFilterChange(i);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleFilterChange(i);
                          }
                        }}
                      >
                        {filters[i] ?
                          <BsCheckLg />
                        : ''}
                      </div>
                      {i}
                    </div>
                  );
                })}
              </div>
            : ''}
          </div>
          <div
            className='orderHistory statusFilter'
            style={{ marginTop: '0px', marginLeft: '62.5rem' }}
          >
            <div
              role='button'
              aria-label='  Sort Orders'
              tabIndex={0}
              className='orderHistory-title statusTitle'
              onClick={() => {
                setOpenItem('history');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setOpenItem('history');
                }
              }}
            >
              Sort Orders
              {open === 'history' ?
                <FiChevronUp
                  className='open'
                  onClick={() => {
                    setOpen();
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpen();
                    }
                  }}
                />
              : <FiChevronDown
                  className='open'
                  onClick={() => {
                    setOpenItem('history');
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setOpenItem('history');
                    }
                  }}
                />
              }
            </div>
            {open === 'history' ?
              <div className='status-filters'>
                {sortOptions.map((i, index) => {
                  return (
                    <div
                      role='button'
                      aria-label={i}
                      tabIndex={0}
                      key={index}
                      className='status-filter'
                      onClick={() => {
                        setSort(i);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          setSort(i);
                        }
                      }}
                    >
                      <div
                        role='button'
                        aria-label={i}
                        tabIndex={0}
                        className='selection-box'
                        onClick={() => {
                          setSort(i);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            setSort(i);
                          }
                        }}
                      >
                        {sort === i ?
                          <BsCheckLg />
                        : ''}
                      </div>
                      {i}
                    </div>
                  );
                })}
              </div>
            : ''}
          </div>
        </div>
        {myQuotes
          ?.filter((i) => {
            if (search && advancedSearchOption) {
              if (advancedSearchOption === 'Zip Code') {
                return i.zipcode?.includes(search);
              } else if (advancedSearchOption === 'Item SKU') {
                return i.items?.some((item) =>
                  item.displaySku?.toLowerCase()?.includes(search?.toLowerCase())
                );
              } else if (advancedSearchOption === 'Reference #') {
                return i.refNum?.toLowerCase()?.includes(search.toString().toLowerCase());
              } else if (advancedSearchOption === 'Quote #') {
                return i.quoteNum?.includes(search);
              } else if (advancedSearchOption === 'User') {
                return i?.rep?.toLowerCase()?.includes(search.toString().toLowerCase());
              }
            } else {
              return true;
            }
            // return search ? i.quoteNum.includes(search) : true;
          })
          ?.filter((i) => {
            return Object.values(filters).every((el) => el === false) ?
                true
              : filters[i.status?.toLowerCase()];
          })
          ?.sort((a, b) => {
            switch (sort) {
              case 'Quote Number':
                return parseInt(a.quoteNum) <= parseInt(b.quoteNum) ? -1 : 1;
              case 'Total Cost':
                return parseInt(a.amount) <= parseInt(b.amount) ? -1 : 1;
              case 'Date Submitted':
                return new Date(b.submitted) - new Date(a.submitted) <= 0 ? 1 : -1;
              case 'Status':
                return a.status.toUpperCase() <= b.status.toUpperCase() ? -1 : 1;
              case 'Zip':
                return parseInt(a.zip) <= parseInt(b.zip) ? -1 : 1;
              default:
                return 0;
            }
          })
          ?.slice(show * page, show * page + show)
          ?.map((i, index) => {
            return (
              <SavedQuote
                key={index}
                order={i}
                open={open}
                setOpenItem={setOpenItem}
                getQuotes={getQuotes}
              />
            );
          })}
        {myQuotes?.length > 0 ?
          <Paginator
            currentPage={page}
            totalPages={lastPage}
            setCurrentPage={setPage}
            setShow={setShow}
            show={show}
          />
        : ''}
        <div style={{ height: '6rem' }}></div>
      </div>
    </div>
  );
};

export default SavedQuotes;
