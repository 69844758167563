import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { TiDelete } from 'react-icons/ti';
import { useSelector } from 'react-redux';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import FormatImageLink from '../Shared/FormatImageLink';
import NBToggle from '../Shared/NBToggle';
import CartQuantity from './CartQuantity';

const CartItem = ({ cart, item, removeFromCart, updateQty, allowedAccess }) => {
  const [removeHover, setRemoveHover] = useState(false);
  // const [status, setStatus] = useState('');
  // const [statusIcons, setStatusIcons] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const axiosJWT = useAxiosJWT();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  const decodeAndFormat = FormatImageLink();
  const finishes = [
    'TableTop Color',
    'Bench Color',
    'Seat Color',
    'Stool Color',
    'Premium Edge Color',
    'TableTop Color',
    'Bench Color',
    'Seat Color',
    'Stool Color',
    'Premium Edge Color',
  ];

  async function getStatusIcons() {
    await axiosJWT
      .get(`products/statusIcons`)
      .then(() => {
        // setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }

  async function getStatus() {
    await axiosJWT
      .post('products/getStatus', {
        baseSku: item.baseSku,
      })
      .then(() => {
        // setStatus(response.data[0]?.status);
      })
      .catch((err) => {
        console.error(`error getting status: ${err}`);
      });
  }
  useEffect(async () => {
    await getStatus();
    await getStatusIcons();
  }, []);

  useEffect(() => {
    setQuantity(item?.qty || 1);
  }, [cart]);

  return (
    <div className={`cart-row ${mobileScreen ? 'mobile-cart-row' : ''}`}>
      {/* non-mobile design */}
      {
        !mobileScreen ?
          <>
            <div className='rowImage'>
              <div className='image-div'>
                <AdvancedImage
                  cldImg={decodeAndFormat(item?.image).resize(scale().width(190))}
                  alt={item.displaySku}
                />
              </div>
              <div className='modelNum'>{item?.displaySku}</div>
            </div>
            <div className='description'>
              {item?.description}
              <div className='cartItem-finishes'>
                {item?.optionsSelected ?
                  Object.keys(item?.optionsSelected)
                    ?.filter((i) => {
                      return finishes.includes(i);
                    })
                    ?.map((i, index) => {
                      return (
                        <div key={index}>
                          {i}: {item?.optionsSelected[i]}
                        </div>
                      );
                    })
                : ''}
              </div>
            </div>
            <div className='non-boxed'>
              <NBToggle
                sku={item?.item}
                nbAvailable={item?.availableNonBoxed}
                isNBFromCart={item?.isNonBoxed}
              />
            </div>
            <div className='cart-quanity-cont'>
              <CartQuantity
                quantity={item?.qty}
                updateQty={updateQty}
                displaySku={item?.displaySku}
                uom={item.uom}
                quantityState={quantity}
                setQuantityState={setQuantity}
              />
            </div>
            {allowedAccess && (
              <>
                <div
                  style={{
                    borderRadius: '15px',
                    height: '25px',
                    width: '65px',
                    font:
                      item.price === 'Custom Pricing' ? 'normal normal 500 14px/16px Poppins' : '',
                  }}
                  className={item.price ? 'description' : 'loading-price'}
                >
                  {item.price !== 'Custom Pricing' ?
                    parseFloat(item.price)?.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2,
                    })
                  : item.price}
                </div>
                <div
                  style={{
                    borderRadius: '15px',
                    height: '25px',
                    width: '85px',
                  }}
                  className={item.price ? 'description' : 'loading-price'}
                >
                  {item.price !== 'Custom Pricing' ?
                    (item.price * item?.qty)?.toLocaleString(undefined, {
                      style: 'currency',
                      currency: 'USD',
                      maximumFractionDigits: 2,
                    })
                  : ''}
                </div>
              </>
            )}
            <div
              className='remove-from-cart'
              role='button'
              aria-label='Remove From Cart'
              tabIndex={0}
              onClick={() => {
                removeFromCart(item.displaySku);
              }}
              onMouseEnter={() => {
                setRemoveHover(true);
              }}
              onMouseLeave={() => {
                setRemoveHover(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  removeFromCart(item.displaySku);
                }
              }}
            >
              <TiDelete />
              <div style={{ color: removeHover ? '#fc0909' : '#4D4D4D' }}>Remove</div>
            </div>
          </>
          // mobile design
        : <>
            <div className='product-details'>
              <div className='item-info'>
                <div className='rowImage'>
                  <div className='image-div'>
                    <AdvancedImage
                      cldImg={decodeAndFormat(item?.image).resize(scale().width(190))}
                      alt={item.displaySku}
                    />
                  </div>
                </div>
                <div className='description'>
                  <div className='modelNum'>{item?.displaySku}</div>
                  <div className='model-desc'>
                    {item?.description}
                    <div className='cartItem-finishes'>
                      {item?.optionsSelected ?
                        Object.keys(item?.optionsSelected)
                          ?.filter((i) => {
                            return finishes.includes(i);
                          })
                          ?.map((i, index) => {
                            return (
                              <div key={index}>
                                {i}: {item?.optionsSelected[i]}
                              </div>
                            );
                          })
                      : ''}
                    </div>
                  </div>
                  {allowedAccess ?
                    <div className='price-div'>
                      <div className='price-title'> price:</div>
                      <div className={`${item.price ? 'price-value' : 'loading-price'}`}>
                        {item.price !== 'Custom Pricing' ?
                          parseFloat(item.price)?.toLocaleString(undefined, {
                            style: 'currency',
                            currency: 'USD',
                            minimumFractionDigits: 2,
                          })
                        : item.price}
                      </div>
                    </div>
                  : <div className='quanity-div'>
                      <div className='quanity-title'>QUANITY:</div>
                      <div className='cart-quanity-cont'>
                        <CartQuantity
                          quantity={item?.qty}
                          updateQty={updateQty}
                          displaySku={item?.displaySku}
                          uom={item.uom}
                          quantityState={quantity}
                          setQuantityState={setQuantity}
                        />
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div
                role='button'
                aria-label='Remove From Cart'
                tabIndex={0}
                className='remove-from-cart'
                onClick={() => {
                  removeFromCart(item.displaySku);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    removeFromCart(item.displaySku);
                  }
                }}
              >
                <IoIosCloseCircleOutline />
              </div>
            </div>
            {allowedAccess && (
              <div className='item-totals'>
                <div className='cart-quanity-cont'>
                  <CartQuantity
                    quantity={item?.qty}
                    updateQty={updateQty}
                    displaySku={item?.displaySku}
                    uom={item.uom}
                    quantityState={quantity}
                    setQuantityState={setQuantity}
                  />
                </div>
                <div className='subtotal-div'>
                  <div className='subtotal-title'>subtotal:</div>
                  <div className={`${item.price ? 'subtotal-value' : 'loading-price'}`}>
                    {item.price !== 'Custom Pricing' ?
                      (item.price * item?.qty)?.toLocaleString(undefined, {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: 2,
                      })
                    : ''}
                  </div>
                </div>
              </div>
            )}
            {item?.availableNonBoxed ?
              <div className='product-nonboxed'>
                <div className='non-boxed-title'>nonboxed: </div>
                <NBToggle
                  className='non-boxed-toggle'
                  sku={item?.item}
                  nbAvailable={item?.availableNonBoxed}
                  isNBFromCart={item?.isNonBoxed}
                />
              </div>
            : ''}
          </>

      }
    </div>
  );
};

export default CartItem;
