import Axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ImageSlider from '../Shared/ImageSlider';
import DiscoverProductsCard from './DiscoverProductsCard';

const DiscoverProducts = () => {
  const [products, setProducts] = useState();
  const mobileScreen = useSelector((state) => state.isMobile.value);

  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

  function getLatestYear(newProductsObj) {
    // Get the keys (years) in the newProductsByYear object
    var years = Object.keys(newProductsObj.newProductsByYear);
    // Return the maximum year among the keys
    return Math.max(...years);
  }

  function getData() {
    Axios.get(`${apiAddress}/content/getNewProducts`).then((response) => {
      var latestYear = getLatestYear(response.data);
      setProducts(response.data.newProductsByYear[latestYear]);
    });
  }

  useEffect(() => {
    getData();
  }, []);

  var productCardsArray = [];

  products?.map((p) =>
    productCardsArray.push(
      <DiscoverProductsCard
        imageUrl={p.image}
        category={p.category}
        itemName={p.itemName}
        link={p.link}
        key={p.key}
      />
    )
  );

  return (
    <div>
      <h1 className='aboutTitle discoverTitle'>
        DISCOVER OUR NEW PRODUCTS<div className='home-underline'></div>
      </h1>
      <p className='discover-p'>
        Explore the most recent additions to our collection of quality furniture!
      </p>
      <ImageSlider
        images={productCardsArray}
        numOfDisplay={mobileScreen.isMobile ? productCardsArray.length : 4}
      />
      <button
        id='discoverProductsViewAll'
        onClick={() => {
          window.open('/new');
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            window.open('/new');
          }
        }}
      >
        VIEW ALL
      </button>
    </div>
  );
};

export default DiscoverProducts;
