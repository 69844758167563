import { AdvancedImage } from '@cloudinary/react';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import clipboardCopy from 'clipboard-copy';
import { useEffect, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { CgSoftwareDownload } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import FormatImageLink from '../Shared/FormatImageLink';
const PdfTile = ({
  image,
  addToSelected,
  removeFromSelected,
  clearList,
  selectedList,
  listItems,
}) => {
  const [selected, setSelected] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  useEffect(() => {
    setSelected(false);
  }, [clearList]);

  useEffect(() => {
    if (
      selectedList?.some(
        (item) => item.imageLink === image.imageLink && item.modelNum === image.modelNum
      )
    ) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedList]);

  function handleClick() {
    if (!selected) {
      addToSelected(image);
    } else {
      removeFromSelected(image);
    }
  }

  const decodeAndFormat = FormatImageLink();
  return (
    <>
      {listItems ?
        <div
          role='button'
          aria-label={`Add ${image.modelNum} to the list`}
          tabIndex={0}
          className='mediaListItem'
          style={{
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
          onClick={handleClick()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleClick();
            }
          }}
        >
          <div
            role='button'
            aria-label={`Add ${image.modelNum} to the list`}
            tabIndex={0}
            onClick={handleClick}
            className={'selection-box'}
            style={{
              borderColor: selected ? '#004D44' : '#939393',
              background: selected ? '#004D44' : 'white',
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
          >
            {selected ?
              <FaCheck style={{ color: 'white' }} />
            : <div style={{ height: '16px', width: '16px' }}></div>}
          </div>
          <div
            role='button'
            aria-label={`Add ${image.modelNum} to the list`}
            tabIndex={0}
            className='tile-img'
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
          >
            <AdvancedImage
              cldImg={decodeAndFormat(image.imageLink).format('png').extract(getPage().byNumber(1))}
              alt={image.modelNum}
            />
          </div>
          <div className='skuTitle'>{image.modelNum}</div>
          <div className='mediaTile-buttons'>
            <div
              role='button'
              aria-label='Download the PDF'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                window.open(`${image.imageLink}`, '_blank');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  window.open(`${image.imageLink}`, '_blank');
                }
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              role='button'
              aria-label='Copy the PDF Link'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                setCopied(true);
                clipboardCopy(`${image.imageLink}`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setCopied(true);
                  clipboardCopy(`${image.imageLink}`);
                }
              }}
            >
              <AiOutlineLink />
              {copied ?
                <div
                  className='notice'
                  style={{ left: '50%' }}
                >
                  Link Copied
                </div>
              : ''}
            </div>
          </div>
        </div>
      : <div
          className='mediaTile'
          style={{
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
        >
          <div className='skuTitle'>{image.modelNum}</div>
          <div
            role='button'
            aria-label={`Add ${image.modelNum} to the list`}
            tabIndex={0}
            className='tile-img'
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
          >
            <AdvancedImage
              cldImg={decodeAndFormat(image.imageLink).format('png').extract(getPage().byNumber(1))}
              alt={image.modelNum}
            />
          </div>
          <div className='mediaTile-buttons'>
            <div
              role='button'
              aria-label={`Add ${image.modelNum} to the list`}
              tabIndex={0}
              onClick={handleClick}
              className={'selection-box'}
              style={{
                borderColor: selected ? '#004D44' : '#939393',
                background: selected ? '#004D44' : 'white',
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick();
                }
              }}
            >
              {selected ?
                <FaCheck style={{ color: 'white' }} />
              : ''}
            </div>
            <div
              role='button'
              aria-label='Download the PDF'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                window.open(`${image.imageLink}`, '_blank');
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  window.open(`${image.imageLink}`, '_blank');
                }
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              role='button'
              aria-label='Copy the PDF Link'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                setCopied(true);
                clipboardCopy(`${image.imageLink}`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setCopied(true);
                  clipboardCopy(`${image.imageLink}`);
                }
              }}
            >
              <AiOutlineLink />
              {copied ?
                <div
                  className='notice'
                  style={{ left: '50%' }}
                >
                  Link Copied
                </div>
              : ''}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PdfTile;
