import { AdvancedImage, lazyload } from '@cloudinary/react';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import { scale } from '@cloudinary/url-gen/actions/resize';
import clipboardCopy from 'clipboard-copy';
import { useEffect, useState } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { CgSoftwareDownload } from 'react-icons/cg';
import { FaCheck } from 'react-icons/fa';
import FormatImageLink from '../Shared/FormatImageLink';
import decodeCloudinary from '../../hooks/decodeCloudinary';
const MediaTile = ({
  image,
  mainImage,
  setMainImage,
  addToSelected = false,
  removeFromSelected = false,
  clearList = false,
  selectedList,
  auth,
  pdf = false,
  setMultiplePages,
  pageCount = false,
  video = false,
  setVideoPlayer,
}) => {
  const decodeAndFormat = FormatImageLink();
  const decodeCloudinaryURL = decodeCloudinary();
  const [selected, setSelected] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  useEffect(() => {
    setSelected(false);
  }, [clearList]);

  useEffect(() => {
    if (selectedList?.includes(image)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedList]);
  function handleClick() {
    if (!selected && addToSelected) {
      addToSelected(image);
    } else if (removeFromSelected) {
      removeFromSelected(image);
    }
  }

  return (
    <>
      <div
        role='button'
        aria-label='Image'
        tabIndex={0}
        className='mediaTile'
        style={{
          border: mainImage === image ? '1px solid #00435B' : '1px solid #BBBBBA',
          cursor: 'pointer',
        }}
        onClick={() => {
          setMainImage(image);
          if (pdf) {
            setMultiplePages(pageCount);
            setVideoPlayer(false);
          } else if (video) {
            setVideoPlayer(true);
            setMultiplePages(false);
          } else {
            setMultiplePages(false);
            setVideoPlayer(false);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setMainImage(image);
            if (pdf) {
              setMultiplePages(pageCount);
              setVideoPlayer(false);
            } else if (video) {
              setVideoPlayer(true);
              setMultiplePages(false);
            } else {
              setMultiplePages(false);
              setVideoPlayer(false);
            }
          }
        }}
      >
        {auth?.roles?.includes(3500) || auth?.roles?.includes(3001) ?
          <div
            role='button'
            aria-label='checkbox'
            tabIndex={0}
            onClick={handleClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleClick();
              }
            }}
            className={'selection-box'}
            style={{
              borderColor: selected ? '#004D44' : '#939393',
              background: selected ? '#004D44' : 'white',
            }}
          >
            {selected ?
              <FaCheck style={{ color: 'white' }} />
            : <div style={{ height: '16px', width: '16px' }}></div>}
          </div>
        : ''}
        <div className='tile-img'>
          {pdf ?
            <AdvancedImage
              cldImg={decodeAndFormat(image)
                .resize(scale().width(300))
                .format('png')
                .extract(getPage().byNumber(1))}
              plugins={[lazyload()]}
              alt='pdf'
            />
          : video ?
            <div>
              <img
                src={image.replace('.mp4', '.jpg')}
                alt='Video'
              ></img>
              <BsFillPlayCircleFill
                style={{
                  position: 'absolute',
                  color: 'white',
                  top: '35%',
                  left: '35%',
                  height: '2rem',
                  width: '2rem',
                  boxShadow: '0px 0px 0px #BBBBBA',
                  borderRadius: '3rem',
                  background: '#BBBBBA',
                }}
              />
            </div>
          : <AdvancedImage
              cldImg={decodeAndFormat(image).resize(scale().width(250))}
              plugins={[lazyload()]}
              alt='Product'
            />
          }
        </div>
        {auth?.roles?.includes(3500) || auth?.roles?.includes(3001) ?
          <div className='mediaTile-buttons'>
            <div
              role='button'
              aria-label='Download the Image'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                video ?
                  window.open(`${image}`)
                : window.open(
                    `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${decodeCloudinaryURL(image)}`,
                    '_blank'
                  );
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  video ?
                    window.open(`${image}`)
                  : window.open(
                      `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${decodeCloudinaryURL(image)}`,
                      '_blank'
                    );
                }
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              role='button'
              aria-label='Copy the Image'
              tabIndex={0}
              className='download-img'
              onClick={() => {
                setCopied(true);
                clipboardCopy(`${image}`);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setCopied(true);
                  clipboardCopy(`${image}`);
                }
              }}
            >
              <AiOutlineLink />
              {copied ?
                <div
                  className='notice'
                  style={{ left: '50%' }}
                >
                  Link Copied
                </div>
              : ''}
            </div>
          </div>
        : ''}
      </div>
    </>
  );
};

export default MediaTile;
