import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useEffect, useState } from 'react';
import { LiaSearchSolid } from 'react-icons/lia';
import { useSelector } from 'react-redux';
import FormatImageLink from '../Shared/FormatImageLink';
import useGeneralImages from '../../hooks/useGeneralImages';

const AddtoCart = ({
  modelNum,
  setModelNum,
  quantity,
  setQuantity,
  addToCart,
  skuList,
  getFinishes,
  searchVal,
  setSearchVal,
  resetValues,
  setAddClicked,
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const { generalImages } = useGeneralImages();
  // eslint-disable-next-line no-unused-vars
  const [indexFocus, setIndexFocus] = useState(-1);
  const [isActive, setIsActive] = useState(false);
  const decodeAndFormat = FormatImageLink();

  function handleAdd() {
    if (modelNum.BaseModelNum.toString().includes('x')) {
      getFinishes();
    } else {
      addToCart(
        modelNum.FreightQuoteModelNum,
        quantity,
        modelNum.ModelNum,
        modelNum.ShortDescription,
        modelNum.imageLink,
        false,
        modelNum.productInfo ? modelNum.productInfo.ModelNum : modelNum.BaseModelNum,
        parseInt(modelNum.uom) * parseInt(quantity),
        parseInt(modelNum.uom),
        modelNum.ShipsNonBoxed,
        false
      );
      if (quantity) {
        resetValues();
      }
    }
  }
  useEffect(() => {
    if (mobileScreen && quantity === 1) {
      handleAdd();
    }
  }, [quantity]);

  return (
    <>
      {
        !mobileScreen ?
          <div className='search-sku-div'>
            <div className='flex-left'>
              <h3>Search by SKU</h3>
              <div className='underline'></div>
              <div className='search'>
                <h4>Add SKU</h4>
                <div className='modelNum-input'>
                  <input
                    type='text'
                    name='searchVal'
                    placeholder='Search SKU'
                    id='searchVal'
                    value={modelNum ? modelNum.BaseModelNum : searchVal}
                    onChange={(e) => {
                      if (modelNum?.ModelNum) {
                        setModelNum('');
                      }
                      setSearchVal(e.target.value);
                    }}
                    onFocus={() => {
                      setShowDrop(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const findSearchVal = skuList?.filter((i) => {
                          return (
                            i.BaseModelNum?.toString()?.toUpperCase() ===
                            searchVal?.toString()?.toUpperCase()
                          );
                        });
                        if (findSearchVal) {
                          setModelNum(findSearchVal[0]);
                          setShowDrop(false);
                        }
                      }
                    }}
                    required
                  ></input>

                  {skuList && searchVal && showDrop ?
                    <div className='modelNum-drop-div'>
                      <div className='modelNum-dropdown'>
                        {skuList
                          ?.filter((i) => {
                            return i.BaseModelNum?.toString()
                              ?.toUpperCase()
                              ?.includes(searchVal?.toString()?.toUpperCase());
                          })
                          ?.map((i, index) => {
                            return (
                              <div
                                role='button'
                                aria-label={i.BaseModelNum}
                                tabIndex={0}
                                key={index}
                                className={`modelNum-list-item ${
                                  index === indexFocus ? 'list-item-focus' : ''
                                }`}
                                onClick={() => {
                                  setModelNum(i);
                                  setShowDrop(false);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setModelNum(i);
                                    setShowDrop(false);
                                  }
                                }}
                              >
                                {i.BaseModelNum}: {i.ShortDescription}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  : ''}
                </div>
                <div
                  role='button'
                  aria-label='Minus'
                  tabIndex={0}
                  className='minus'
                  onClick={() => {
                    if (quantity) {
                      setQuantity(quantity - 1);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (quantity) {
                        setQuantity(quantity - 1);
                      }
                    }
                  }}
                >
                  -
                </div>
                <div className='quantity-input'>
                  <input
                    type='integer'
                    name='quantity'
                    placeholder='Quantity'
                    id='quantity'
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(parseInt(e.target.value));
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleAdd();
                      }
                    }}
                    required
                  ></input>
                </div>
                <div
                  role='button'
                  aria-label='Plus'
                  tabIndex={0}
                  className='plus'
                  onClick={() => {
                    if (quantity) {
                      setQuantity(quantity + 1);
                    } else {
                      setQuantity(1);
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (quantity) {
                        setQuantity(quantity + 1);
                      } else {
                        setQuantity(1);
                      }
                    }
                  }}
                >
                  +
                </div>
                <button
                  className='addToCart'
                  onClick={handleAdd}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAdd();
                    }
                  }}
                >
                  Add
                </button>
              </div>
              <div className='search-description'>{modelNum ? modelNum.ShortDescription : ''}</div>
            </div>
            <div className='flex-right'>
              {modelNum ?
                <AdvancedImage
                  cldImg={decodeAndFormat(
                    modelNum.imageLink ?
                      modelNum.imageLink
                    : generalImages.find((i) => i.publicId === 'image-coming-soon_lzhzdk')
                        ?.imageLink
                  ).resize(scale().width(250))}
                />
              : ''}
            </div>
          </div>
          //mobile design
        : <div className='search-sku-div'>
            <div className='innerSkuSearch'>
              <h3>Search by SKU</h3>
              <div className='search'>
                <div
                  className={`modelNum-input searchValInput ${isActive ? 'typingSearchBox' : ''} `}
                >
                  <div className='searchIcon'>
                    <LiaSearchSolid />
                  </div>
                  <input
                    type='text'
                    name='searchVal'
                    placeholder='SKU'
                    id='searchVal'
                    value={modelNum ? modelNum.BaseModelNum : searchVal}
                    onChange={(e) => {
                      if (modelNum?.ModelNum) {
                        setModelNum('');
                      }
                      setSearchVal(e.target.value);
                    }}
                    onFocus={() => {
                      setShowDrop(true);
                      setIsActive(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const findSearchVal = skuList?.filter((i) => {
                          return (
                            i.BaseModelNum?.toString()?.toUpperCase() ===
                            searchVal?.toString()?.toUpperCase()
                          );
                        });
                        if (findSearchVal) {
                          setModelNum(findSearchVal[0]);
                          setShowDrop(false);
                        }
                      }
                    }}
                    required
                  ></input>
                  {skuList && searchVal && showDrop ?
                    <div className='modelNum-drop-div'>
                      <div className='modelNum-dropdown'>
                        {skuList
                          ?.filter((i) => {
                            return i.BaseModelNum?.toString()
                              ?.toUpperCase()
                              ?.includes(searchVal?.toString()?.toUpperCase());
                          })
                          ?.map((i, index) => {
                            return (
                              <div
                                role='button'
                                aria-label={i.BaseModelNum}
                                tabIndex={0}
                                key={index}
                                className={`modelNum-list-item ${
                                  index === indexFocus ? 'list-item-focus' : ''
                                }`}
                                onClick={() => {
                                  setModelNum(i);
                                  setShowDrop(false);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    setModelNum(i);
                                    setShowDrop(false);
                                  }
                                }}
                              >
                                {i.BaseModelNum}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  : ''}
                </div>
                <button
                  className='addToCart'
                  onClick={() => {
                    if (modelNum) {
                      setQuantity(1);
                    }
                    setAddClicked(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (modelNum) {
                        setQuantity(1);
                      }
                      setAddClicked(true);
                    }
                  }}
                >
                  Add
                </button>
              </div>
              <div className={modelNum ? 'search-description' : ''}>
                {modelNum ? modelNum.ShortDescription : ''}
              </div>
            </div>
          </div>

      }
    </>
  );
};

export default AddtoCart;
