import Axios from 'axios';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();
  const [allowed, setAllowed] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getRoles() {
    const response = await Axios.post(`${apiAddress}/content/getRouteRoles`, {
      route:
        location.pathname === '/catalog' ? location.pathname + location.search : location.pathname,
    });
    const routes = response.data?.map((i) => i.permission_role);
    setAllowed(
      auth?.roles?.some((i) => routes?.includes(i.toString())) ||
        auth?.accounts
          ?.find((account) => account.last_login === 1)
          ?.AccountRoles?.some((i) => routes?.includes(i.toString()))
    );

    if (routes.length <= 0) {
      console.error(`no routes in route_permissions table for ${location.pathname}`);
    }
    setLoading(false);
  }

  useEffect(() => {
    getRoles();
  }, [auth?.rep, auth?.roles]);

  if (loading) {
    return null;
  }

  return (
    allowed ? <Outlet />
    : auth?.rep ?
      <Navigate
        to='/unauthorized'
        state={{ from: location }}
        replace
      />
    : <Navigate
        to='/requestlogin'
        state={{ from: location }}
        replace
      />
  );
};

export default RequireAuth;
