import { useEffect } from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import '../../CSS/NPSportal/reusableComponents.css';
import BasicButton from './basicButton';

const ReusablePopup = ({ innerComponent, title, open, setOpen, continueAction, customStyle }) => {
  useEffect(() => {
    if (open) {
      document.body.classList.add('popup-open');
    } else {
      document.body.classList.remove('popup-open');
    }
  }, [open]);
  return (
    open && (
      <div className={`${customStyle ? customStyle : ''} basic-popup`}>
        <div
          role='button'
          aria-label='Close Popup'
          tabIndex={0}
          className='modal'
          onClick={() => {
            setOpen(false);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setOpen(false);
            }
          }}
        ></div>
        <div className='popup'>
          <IoCloseCircleOutline
            style={{ height: '1.7rem', width: '1.7rem' }}
            className='close-button'
            onClick={() => {
              setOpen(false);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setOpen(false);
              }
            }}
          />
          <h2 style={{ fontSize: '25px', lineHeight: '27px' }}>{title}</h2>
          <div className='underline'></div>
          <div className='inner-box'>{innerComponent}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              width: '705px',
              margin: '30px',
            }}
          >
            {continueAction && (
              <>
                <BasicButton
                  onClick={() => setOpen(false)}
                  text={'Cancel'}
                  type={'basic-white-button'}
                />

                <BasicButton
                  onClick={() => {
                    if (continueAction) {
                      continueAction();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      if (continueAction) {
                        continueAction();
                      }
                    }
                  }}
                  text={'Continue'}
                  type={'white-bordered-button'}
                />
              </>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default ReusablePopup;
