import React from 'react';
import useGeneralImages from '../../hooks/useGeneralImages';

const FullPageLoading = () => {
  const { generalImages } = useGeneralImages();
  const loading = generalImages.find((i) => i.publicId === 'LoadingProducts_qqfyyk')?.imageLink;

  return (
    <img
      src={loading}
      style={{
        zIndex: '151',
        position: 'fixed',
        top: '0',
        left: '0',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        opacity: '.9',
      }}
      alt='Loading Icon'
    />
  );
};

export default FullPageLoading;
