import { AdvancedImage } from '@cloudinary/react';
import { fill } from '@cloudinary/url-gen/actions/resize';
import clipboardCopy from 'clipboard-copy';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import FormatImageLink from '../../Components/Shared/FormatImageLink';
import { updateProductInfo } from '../../ReduxSlices/productState';
import { useCart } from '../../context/CartContext';
import ProductCart from './ProductCart';

const NoConfigDisplay = () => {
  const { product, skuStatus } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const { addToCart } = useCart();

  const decodeAndFormat = FormatImageLink();

  return (
    <div className='no-config-contianer'>
      <div
        style={{
          marginTop: '15px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div className='sku-title'>SKU:</div>
        <div
          role='button'
          tabIndex={0}
          className={`sku sku-${skuStatus}`}
          onClick={() => {
            if (skuStatus === 'Completed') {
              dispatch(updateProductInfo({ skuStatus: 'Copied' }));
              clipboardCopy(product.modelNum);
              setTimeout(() => {
                dispatch(updateProductInfo({ skuStatus: 'Completed' }));
              }, 2000);
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (skuStatus === 'Completed') {
                dispatch(updateProductInfo({ skuStatus: 'Copied' }));
                clipboardCopy(product.modelNum);
                setTimeout(() => {
                  dispatch(updateProductInfo({ skuStatus: 'Completed' }));
                }, 2000);
              }
            }
          }}
        >
          <div>{product?.modelNum}</div>
        </div>
        {skuStatus === 'Copied' && (
          <div
            className='sku-status'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              margin: '5px',
            }}
          >
            SKU {skuStatus}
          </div>
        )}
      </div>
      <Scrollbar
        id='scrollbar'
        style={{
          width: 600,
          height: '94%',
        }}
      >
        <div className='no-config-product-details'>
          {product?.envImage ?
            <div className='product-details-image'>
              <AdvancedImage
                cldImg={decodeAndFormat(product?.envImage).resize(fill().width(570).height(324))}
              />
            </div>
          : <div className='product-long-desc-div'>{product?.productInfo?.LongDescription}</div>}
          <ProductCart
            addToCart={addToCart}
            page={'noConfig'}
          />
        </div>
      </Scrollbar>
    </div>
  );
};

export default NoConfigDisplay;
