import saveAs from 'file-saver';
import * as XLSX from 'xlsx/xlsx.mjs';
const useExportToExcel = () => {
  function ExportToExcel(dataObjects, fileName) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const wb = XLSX.utils.book_new();
    for (let i = 0; i < Object.keys(dataObjects).length; i++) {
      const ws = XLSX.utils.json_to_sheet(dataObjects[Object.keys(dataObjects)[i]]);
      XLSX.utils.book_append_sheet(wb, ws, Object.keys(dataObjects)[i]);
    }

    const eb = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([eb], { type: EXCEL_TYPE });
    saveAs(blob, fileName + EXCEL_EXTENSION);
  }
  return ExportToExcel;
};

export default useExportToExcel;
