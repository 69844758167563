import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { FaBriefcase, FaEnvelope, FaEye, FaEyeSlash } from 'react-icons/fa';
import { IoPersonSharp } from 'react-icons/io5';
import { RiLock2Fill } from 'react-icons/ri';
import useAuth from '../../hooks/useAuth';
// import { RiInformationFill } from "react-icons/ri";
import { FaCheckCircle } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi';
import { RiErrorWarningFill } from 'react-icons/ri';
//import { useSelector } from "react-redux";
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const NAME_REGEX = /^[a-z ,.'-]+$/i;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
const REGISTER_URL = '/register';
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/;

const EMAIL_REGEX =
  // /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  /\S+@\S+\.\S+/;
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const SignupForm = ({
  username,
  setUsername,
  password,
  setPassword,
  setDisplayLogin,
  setDisplayBoolean,
  invite,
  setShowRegistered,
  setShowSignup,
  setForm,
}) => {
  const userRef = useRef();
  const axiosJWT = useAxiosJWT();
  // const nameRef = useRef();
  const errRef = useRef();

  const [infoHover, setInfoHover] = useState(false);
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [company, setCompany] = useState('');
  const [accountNum, setAccountNum] = useState('');
  const [accountRoles, setAccountsRoles] = useState([]);
  const [suggestedRoles, setSuggestedRoles] = useState([]);
  const [showRoles, setShowRoles] = useState(false);
  // const [email, setEmail] = useState("");

  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [validCompany, setValidCompany] = useState(false);
  const [companyFocus, setCompanyFocus] = useState(false);

  const [validPhone, setValidPhone] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [validPwd, setValidPwd] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPwd, setMatchPwd] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [errMsg, setErrMsg] = useState('');
  // const [success, setSuccess] = useState();

  const { setAuth } = useAuth();
  // const [existingDealer, setExistingDealer] = useState(false);
  // const [existingDealerComment, setExistingDealerComment] = useState('');
  const [toggleEye, setToggleEye] = useState(false);

  //const mobileScreen = useSelector((state) => state.isMobile.value);

  // const closeForm = () => {
  //   setDisplayBoolean(false);
  //   setUsername("");
  //   setPassword("");
  //   setSuccess();
  // };

  // useEffect(() => {
  //   nameRef.current.focus();
  // }, []);
  useEffect(() => {
    axiosJWT
      .post(`register/getAccountRoles`)
      .then((response) => {
        setAccountsRoles(response.data.filter((i) => i.permission));
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }, []);
  const handleCheckedRoleChange = (role, checked) => {
    if (checked) {
      setSuggestedRoles([...suggestedRoles, role]);
    } else {
      setSuggestedRoles(suggestedRoles.filter((i) => i !== role));
    }
  };

  useEffect(() => {
    setValidName(NAME_REGEX.test(fullName));
    // setValidName(true);
  }, [fullName]);

  useEffect(() => {
    setValidPwd(PWD_REGEX.test(password));
    setValidMatch(password === matchPwd);
  }, [password, matchPwd]);

  useEffect(() => {
    if (company !== '') {
      setValidCompany(true);
    } else {
      setValidCompany(false);
    }
  }, [company]);
  useEffect(() => {
    setValidPhone(PHONE_REGEX.test(phone));
  }, [phone]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(username));
  }, [username]);
  // useEffect(() => {
  //   setErrMsg("");
  // }, [username, password, matchPwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2 || !validMatch) {
      if (!v1) {
        setEmailFocus(true);
      }
      if (!v2) {
        setPwdFocus(true);
      }
      if (!validMatch) {
        setMatchFocus(true);
      }
      setErrMsg('Invalid Entry');
      return;
    }
    try {
      const response = await axios.post(
        `${apiAddress}${REGISTER_URL}`,
        JSON.stringify({
          fullName,
          company,
          phone,
          username,
          password,
          accountNum,
          roles: suggestedRoles.join(', '),
          invite,
          // existingDealer,
          // existingDealerComment,
        }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const rep = response?.data?.rep;
      const dealer = response?.data?.dealer;
      const phoneNum = response?.data?.phone;
      const accounts = response?.data?.accounts;
      setAuth({
        user: username,
        pwd: password,
        rep,
        dealer,
        phoneNum,
        roles: roles ? roles : [],
        accessToken,
        accounts,
      });

      // setSuccess(true);
      //mobileScreen.isMobile ? setShowRegistered(true) : setDisplayBoolean(false);
      window.location.reload();
      setShowSignup(false);
      setForm('showSignup');
      setShowRegistered(true);
      setDisplayBoolean(true);
      setDisplayLogin(false);

      //clear state and controlled inputs
      //need value attrib on inputs for this
      setUsername('');
      setPassword('');
      setMatchPwd('');
    } catch (err) {
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg(err.response.data?.error?.message);
      } else {
        setErrMsg('Registration Failed');
      }
      errRef.current.focus();
    }
    console.error(errMsg);
  };

  useEffect(() => {
    if (invite) {
      setUsername(invite.username);
    }
  }, [invite]);

  return (
    <div>
      {/* {success &&
        <section>
          <h1>Welcome {fullName}</h1>
          <button onClick={closeForm}>Close Register Form</button>
        </section>} */}
      {/* ) : (
        <div>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p> */}
      <form className='login-form'>
        {/* USERNAME FIELD */}
        <div className={`login-input-holder`}>
          <div className={`dealer-icon ${validName ? 'valid-icon' : ''}`}>
            <IoPersonSharp />
          </div>
          <lable
            htmlFor='fullname'
            className='html-hidden-label'
          >
            Full Name
          </lable>
          <input
            type='text'
            name='fullname'
            placeholder='Full Name'
            id='fullname'
            // ref={nameRef}
            autoComplete='name'
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            onFocus={() => {
              setUserFocus(true);
            }}
            className={
              userFocus && !validName ? 'error-input'
              : validName ?
                'valid-input'
              : ''
            }
            // onMouseEnter={() => {
            //   setValidName(true);
            // }}
            // onfocusout={() => {
            //   setUserFocus(false);
            // }}
            required
          ></input>
          {validName ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : userFocus ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
        </div>
        {/* USERNAME ERROR */}
        <div className='error-msg-text'>
          {userFocus && !validName ? 'Required field is missing.' : ''}
        </div>

        {/* COMPANY FIELD */}
        <div className={`login-input-holder`}>
          <div className={`dealer-icon ${validCompany ? 'valid-icon' : ''}`}>
            <FaBriefcase />
          </div>
          <lable
            htmlFor='company'
            className='html-hidden-label'
          >
            Company Name
          </lable>
          <input
            type='text'
            name='company'
            placeholder='Company Name'
            id='company'
            autoComplete='organization'
            value={company}
            onChange={(e) => {
              setCompany(e.target.value);
            }}
            onFocus={() => {
              setCompanyFocus(true);
            }}
            className={
              companyFocus && !validCompany ? 'error-input'
              : validCompany ?
                'valid-input'
              : ''
            }
            required
          ></input>
          {validCompany ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : companyFocus ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
        </div>
        <div className='error-msg-text'>
          {companyFocus && !validCompany ? 'Required field is missing.' : ''}
        </div>

        {/* Phone Field */}
        <div className={`login-input-holder`}>
          <div className={`dealer-icon ${validPhone ? 'valid-icon' : ''}`}>
            <BsFillTelephoneFill />
          </div>
          <lable
            htmlFor='tel'
            className='html-hidden-label'
          >
            Phone Number
          </lable>
          <input
            type='tel'
            name='tel'
            placeholder='Phone Number'
            id='tel'
            autoComplete='tel'
            value={phone}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            onFocus={() => setPhoneFocus(true)}
            className={
              phoneFocus && !validPhone ? 'error-input'
              : validPhone ?
                'valid-input'
              : ''
            }
          ></input>
          {validPhone ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : phoneFocus ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
        </div>
        <div className='error-msg-text'>
          {phoneFocus && !validPhone ? 'Required field is missing.' : ''}
        </div>

        {/* Email Field */}
        <div className={`login-input-holder `}>
          <div className={`dealer-icon ${validEmail ? 'valid-icon' : ''}`}>
            <FaEnvelope />
          </div>
          <lable
            htmlFor='email'
            className='html-hidden-label'
          >
            Email
          </lable>
          <input
            type='email'
            name='email'
            placeholder='Email'
            id='email'
            autoComplete='email'
            ref={userRef}
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            required
            onFocus={() => {
              setEmailFocus(true);
            }}
            className={
              emailFocus && !validEmail ? 'error-input'
              : validEmail ?
                'valid-input'
              : ''
            }
          ></input>
          {validEmail ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : emailFocus ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
        </div>
        <div className='error-msg-text'>
          {emailFocus && !validEmail ? 'Required field is missing.' : ''}
        </div>
        {/* <label htmlFor="username">
              <FontAwesomeIcon
                icon={faCheck}
                className={validName ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validName || !username ? "hide" : "invalid"}
              />
            </label>
            <p
              id="uidnote"
              className={
                userFocus && username && !validName
                  ? "instructions"
                  : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must enter a valid email address
            </p> */}

        {/* PASSWORD FIELD */}
        <div className={`login-input-holder `}>
          <div className={`dealer-icon ${validPwd ? 'valid-icon' : ''}`}>
            <RiLock2Fill />
          </div>
          <lable
            htmlFor='password'
            className='html-hidden-label'
          >
            Password
          </lable>
          <input
            type={toggleEye ? 'input' : 'password'}
            name='password'
            placeholder='Password'
            id='password'
            autoComplete='new-password'
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
            onFocus={() => setPwdFocus(true)}
            className={
              pwdFocus && !validPwd ? 'error-input'
              : validPwd ?
                'valid-input'
              : ''
            }
            // onBlur={() => setPwdFocus(false)}
          ></input>
          <div className='show-password-icon-div'>
            {!toggleEye ?
              <FaEye
                className={validPwd || pwdFocus ? 'shifted-password-eye' : 'password-eye'}
                onClick={() => setToggleEye(true)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setToggleEye(true);
                  }
                }}
              />
            : <FaEyeSlash
                className={validPwd || pwdFocus ? 'shifted-password-eye' : 'password-eye'}
                onClick={() => setToggleEye(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setToggleEye(false);
                  }
                }}
              />
            }
          </div>
          {validPwd ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : pwdFocus ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
        </div>
        <div
          className='error-msg-text'
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {pwdFocus && !validPwd ? 'See Password Requirements' : ''}
          {pwdFocus && !validPwd ?
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#ACACAC',
                marginLeft: '5px',
              }}
              className='password-info-svg'
            >
              <HiInformationCircle
                onMouseEnter={() => {
                  setInfoHover(true);
                }}
                onMouseLeave={() => {
                  setInfoHover(false);
                }}
                onClick={() => {
                  setInfoHover(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setInfoHover(true);
                  }
                }}
              />
              {infoHover ?
                <div className='password-info-popup'>
                  - At least 8 characters <br></br>- A mixture of both uppercase and lowercase
                  letters. <br></br>- A mixture of letters and numbers. <br></br>- Inclusion of at
                  least one special character, e.g., ! @ # ?
                </div>
              : ''}
            </div>
          : ''}
        </div>

        <div className={`login-input-holder`}>
          <div className={`dealer-icon ${validMatch && validPwd ? 'valid-icon' : ''}`}>
            <RiLock2Fill />
          </div>
          <lable
            htmlFor='confirmPassword'
            className='html-hidden-label'
          >
            Confirm Password
          </lable>
          <input
            type={toggleEye ? 'input' : 'password'}
            name='confirmPassword'
            placeholder='Confirm Password'
            id='confirmPassword'
            autoComplete='off'
            value={matchPwd}
            onChange={(e) => setMatchPwd(e.target.value)}
            required
            onFocus={() => setMatchFocus(true)}
            // onBlur={() => setMatchFocus(false)}
            className={
              matchFocus && !validMatch ? 'error-input'
              : validMatch && validPwd ?
                'valid-input'
              : ''
            }
          ></input>
          <div className='show-password-icon-div'>
            {!toggleEye ?
              <FaEye
                className={
                  (validMatch && validPwd) || (matchFocus && !validMatch) ?
                    'shifted-password-eye'
                  : 'password-eye'
                }
                onClick={() => setToggleEye(true)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setToggleEye(true);
                  }
                }}
              />
            : <FaEyeSlash
                className={
                  (validMatch && validPwd) || (matchFocus && !validMatch) ?
                    'shifted-password-eye'
                  : 'password-eye'
                }
                onClick={() => setToggleEye(false)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setToggleEye(false);
                  }
                }}
              />
            }
          </div>{' '}
          {validMatch && validPwd ?
            <div className='dealer-msg valid'>
              <FaCheckCircle />
            </div>
          : matchFocus && !validMatch ?
            <div className='dealer-msg error'>
              <RiErrorWarningFill />
            </div>
          : <></>}
          {/* <HiInformationCircle /> */}
        </div>
        <div className='error-msg-text'>
          {matchFocus && !validMatch ? 'Passwords do not match.' : ''}
        </div>

        {/* <label htmlFor="confirmPassword">
              <FontAwesomeIcon
                icon={faCheck}
                className={validMatch && matchPwd ? "valid" : "hide"}
              />
              <FontAwesomeIcon
                icon={faTimes}
                className={validMatch || !matchPwd ? "hide" : "invalid"}
              />
            </label> */}
        {/* <p
              id="confirmnote"
              className={
                matchFocus && !validMatch ? "instructions" : "offscreen"
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              Must match the first password input field.
            </p> */}
        {/* <div className="existing-dealer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ margin: "0 10px ", fontWeight: "400" }}>
              Existing Dealer?
            </div>
            <ToggleButton state={existingDealer} setState={setExistingDealer} />
          </div>
          {!existingDealer ? (
            <div
              className={`login-input-holder `}
              style={{ marginTop: "10px" }}
            >
              <div className={`dealer-icon `}>
                <RiLock2Fill />
              </div>
              <input
                type="text"
                name="existingDealerComment"
                placeholder="Explain your interest"
                id="existingDealerComment"
                value={existingDealerComment}
                onChange={(e) => setExistingDealerComment(e.target.value)}
                required
              ></input>
            </div>
          ) : (
            ""
          )}
        </div> */}
        {invite === undefined && (
          <>
            <div
              style={{
                marginLeft: '5px',
                textAlign: 'start',
                marginTop: '0px',
              }}
              className='need-account'
            >
              Suggested Fields:
            </div>
            <div
              style={{
                marginBottom: '10px',
                display: 'flex',
                width: '332px',
                justifyContent: 'space-between',
              }}
            >
              {/* <div
                style={{ width: '9.5rem', padding: '10px' }}
                className={`login-input-holder`}
              > */}
              <lable
                htmlFor='accountNum'
                className='html-hidden-label'
              >
                Account #
              </lable>
              <input
                type='text'
                name='accountNum'
                placeholder='Account #'
                id='accountNum'
                value={accountNum}
                onChange={(e) => {
                  setAccountNum(e.target.value);
                }}
                required
                style={{ width: '8rem', marginLeft: 0, paddingLeft: '1rem', height: '41px' }}
              ></input>
              {/* </div> */}
              <div
                role='button'
                aria-label='Permissions'
                tabIndex={0}
                onClick={() => {
                  showRoles ? setShowRoles(false) : setShowRoles(true);
                }}
                className={`login-input-holder selection `}
                style={{
                  justifyContent: 'space-between',
                  padding: '15px',
                  display: 'flex',
                  width: '170px',
                  cursor: 'pointer',
                  fontWeight: 'initial',
                  marginLeft: '2px',
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    showRoles ? setShowRoles(false) : setShowRoles(true);
                  }
                }}
              >
                Permissions
                {showRoles ?
                  <BiChevronUp
                    onClick={() => {
                      setShowRoles(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setShowRoles(false);
                      }
                    }}
                    className='laminate-select-down laminates-svg'
                  />
                : <BiChevronDown
                    onClick={() => {
                      setShowRoles(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setShowRoles(true);
                      }
                    }}
                    className='laminate-select-down laminates-svg'
                  />
                }
              </div>
              {showRoles && (
                <div
                  style={{
                    width: '170px',
                    marginLeft: '10.18rem',
                    marginTop: '3rem',
                  }}
                  className='userroles-dropdown'
                >
                  {accountRoles?.map((role, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'flex',

                        cursor: 'default',
                      }}
                    >
                      <input
                        type='checkbox'
                        checked={suggestedRoles.includes(role.role)}
                        onChange={(event) =>
                          handleCheckedRoleChange(role.role, event.target.checked)
                        }
                        style={{
                          display: 'initial',
                          cursor: 'pointer',
                          width: '14px',
                          marginLeft: 0,
                        }}
                      />
                      <div>{role.role}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        )}

        <button
          type='submit'
          className='submit'
          onClick={(e) => handleSubmit(e)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              (e) => handleSubmit(e);
            }
          }}
        >
          SIGN UP
        </button>
        <div
          className='error-msg-text'
          style={{ textAlign: 'center', marginBottom: '0' }}
        >
          {errMsg ? `${errMsg}` : ''}
        </div>
        {/* {success ? setDisplayBoolean(false) : "Invalid Entry. Try again."} */}
      </form>
      {/* <br/> */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          style={{ margin: '0px', width: 'auto' }}
          className='need-account'
        >
          Already Have an Account?
        </p>
        <h3
          role='button'
          aria-label='Log in'
          tabIndex={0}
          style={{ margin: '0px 0px 0px 7px' }}
          className='sign-up-link'
          onClick={() => setDisplayLogin(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setDisplayLogin(true);
            }
          }}
        >
          LOGIN
        </h3>
      </div>
    </div>
  );
};
// </div>
// );
// };

export default SignupForm;
