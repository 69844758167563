import { useEffect, useState } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { HiInformationCircle } from 'react-icons/hi';
import { IoSearchSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';
import '../../CSS/LaminateBar.css';
import { updateProductInfo } from '../../ReduxSlices/productState';
import LaminateCube from './LaminateCube';

const LaminateBar = ({
  configSelected,
  laminates,
  assignClass = false,
  setInfoHover,
  setOptionsSelected,
  cartOptionsSelected,
  parentType,
}) => {
  const {
    optionsSelected: productOptionsSelected,
    quickshipProducts,
    product,
  } = useSelector((state) => state.productState.value);
  const optionsSelected =
    parentType === 'productPage' ? productOptionsSelected : cartOptionsSelected;
  const dispatch = useDispatch();
  const [selectedLaminateType, setSelectedLaminateType] = useState(
    quickshipProducts?.some((p) => p.model_num === product?.productInfo?.ModelNum) ? 'quickShip' : (
      'standard'
    )
  ); //popular, standard, premium, quickShip
  const [showSelect, setShowSelect] = useState(false);

  const [search, setSearch] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [searchAll, setSearchAll] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const colorsArrayStandard = laminates.filter((i) => {
    return i.Type === 'Standard';
  });

  const colorsArrayPremium = laminates.filter((i) => {
    return i.Type === 'Premium';
  });

  const quickShipColorsArray = laminates.filter((i) => {
    return (
      quickshipProducts?.length > 0 &&
      quickshipProducts
        ?.filter((p) => p.model_num === product?.productInfo?.ModelNum)
        ?.some((p) => p.tabletopLaminate === i.selectionName || p.benchLaminate === i.selectionName)
    );
  });

  function clicked(i) {
    if (setOptionsSelected) {
      setOptionsSelected({
        ...optionsSelected,
        [configSelected.name]: i.selectionName,
      });
    } else if (parentType === 'productPage') {
      //if already selected, remove selection, else add selection
      if (
        Object.entries(optionsSelected)?.find(
          (index) => index[0] === configSelected.name && index[1] === i.selectionName
        )
      ) {
        const holderObj = { ...optionsSelected };
        delete holderObj[configSelected.name];
        dispatch(
          updateProductInfo({
            optionsSelected: {
              ...holderObj,
            },
          })
        );
      } else {
        dispatch(
          updateProductInfo({
            optionsSelected: {
              ...optionsSelected,
              [configSelected.name]: i.selectionName,
            },
          })
        );
      }
    }

    setSelectedColor(i.selectionName);
  }

  function searchLaminates(arrayIn) {
    var objHolder = [];
    arrayIn
      .filter((i) => {
        if (!search) {
          return true;
        }
        if (i.selectionName.toUpperCase().includes(search.toUpperCase())) {
          return true;
        }
        if (i.VendorCode.toUpperCase().includes(search.toUpperCase())) {
          return true;
        }
        if (i.value.toUpperCase().includes(search.toUpperCase())) {
          return true;
        }

        return false;
      })
      .map((i, index) => {
        objHolder.push(
          <LaminateCube
            colorData={i}
            clicked={clicked}
            selectedColor={selectedColor}
            key={index}
            assignClass={assignClass}
          />
        );
      });
    return objHolder;
  }

  // const hexCodes = {
  //   'Persian Blue': '#005495',
  //   'Hollyberry ': '#ed0707',
  //   'Marigold ': '#f1f505',
  //   Blue: '#3471eb',
  //   Green: '#066332',
  //   'Montana Walnut': '#513420',
  //   'Wild Cherry': '#a7562f',
  //   'Graphite Nebula': '#2c292a',
  //   Black: '#000000',
  //   Burgundy: '#ed0707',
  // };

  useEffect(() => {
    // if (optionsSelected["TableTop Color"]) {
    // dispatch(updateProductInfo({ topHexCode: hexCodes[optionsSelected["TableTop Color"]] }));
    // }
    // if (optionsSelected["Bench Color"]) {
    // dispatch(updateProductInfo({ benchHexCode: hexCodes[optionsSelected["Bench Color"]] }));
    // }
    // if (optionsSelected["Stool Color"]) {
    //dispatch(updateProductInfo({ stoolHexCode: hexCodes[optionsSelected["Stool Color"]] }));
    // }
  }, [optionsSelected, optionsSelected['Stool Color']]);

  useEffect(() => {
    setSelectedColor(optionsSelected[configSelected.name]);
  }, [optionsSelected, configSelected]);

  useEffect(() => {
    setSelectedLaminateType(
      quickshipProducts?.some((p) => p.model_num === product?.productInfo?.ModelNum) ? 'quickShip'
      : 'standard'
    );
  }, [product]);

  return (
    <div className={`laminates-div ${assignClass ? assignClass : ''}`}>
      <div className='laminate-top-bar'>
        <div className='laminate-type-selection'>
          <div className='laminate-select'>
            <div className='select-title'>
              {!mobileScreen && (
                <div
                  id='laminates-infopopup'
                  className='info-div'
                >
                  {selectedLaminateType === 'premium' ?
                    <HiInformationCircle
                      onMouseEnter={() => {
                        setInfoHover(true);
                      }}
                      onMouseLeave={() => {
                        setInfoHover(false);
                      }}
                      className='laminates-svg'
                    />
                  : ''}
                </div>
              )}
              <div
                role='button'
                aria-label={
                  selectedLaminateType === 'premium' ? 'Select Premium Laminate Color'
                  : selectedLaminateType === 'quickShip' ?
                    ' Select Quick Ship Laminate Color'
                  : ' Select Standard Laminate Color'
                }
                tabIndex={0}
                onClick={() => {
                  setShowSelect(!showSelect);
                }}
                className='laminate-select-down'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowSelect(!showSelect);
                  }
                }}
              >
                {selectedLaminateType === 'premium' ?
                  'Select Premium Laminate Color'
                : selectedLaminateType === 'quickShip' ?
                  ' Select Quick Ship Laminate Color'
                : ' Select Standard Laminate Color'}
              </div>
              <BiChevronDown
                onClick={() => {
                  setShowSelect(!showSelect);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setShowSelect(!showSelect);
                  }
                }}
                className='laminate-select-down laminates-svg'
              />
            </div>
            {showSelect ?
              <div className='select-options'>
                {quickshipProducts?.some((p) => p.model_num === product?.productInfo?.ModelNum) && (
                  <div
                    role='button'
                    aria-label='Select Quick Ship Laminate Color'
                    tabIndex={0}
                    className={`laminate-option ${
                      selectedLaminateType === 'quickShip' ? 'laminate-option-selected' : ''
                    }`}
                    onClick={() => {
                      setSelectedLaminateType('quickShip');
                      setShowSelect(false);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        setSelectedLaminateType('quickShip');
                        setShowSelect(false);
                      }
                    }}
                  >
                    Select Quick Ship Laminate Color
                  </div>
                )}
                <div
                  role='button'
                  aria-label='Select Standard Laminate Color'
                  tabIndex={0}
                  className={`laminate-option ${
                    selectedLaminateType === 'standard' ? 'laminate-option-selected' : ''
                  }`}
                  onClick={() => {
                    setSelectedLaminateType('standard');
                    setShowSelect(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSelectedLaminateType('standard');
                      setShowSelect(false);
                    }
                  }}
                >
                  Select Standard Laminate Color
                </div>
                <div
                  role='button'
                  aria-label='Select Premium Laminate Color'
                  tabIndex={0}
                  className={`laminate-option ${
                    selectedLaminateType === 'premium' ? 'laminate-option-selected' : ''
                  }`}
                  onClick={() => {
                    setSelectedLaminateType('premium');
                    setShowSelect(false);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setSelectedLaminateType('premium');
                      setShowSelect(false);
                    }
                  }}
                >
                  Select Premium Laminate Color
                </div>
              </div>
            : ''}
          </div>
        </div>
        {assignClass ?
          ''
        : <div className='search-laminates'>
            <input
              placeholder='Search by Name/Code'
              type='text'
              name='search'
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>

            <IoSearchSharp
              className='laminates-svg'
              style={{ marginRight: '-35px' }}
            />
            <div
              role='button'
              aria-label='Search All'
              tabIndex={0}
              style={{
                backgroundColor: searchAll ? 'grey' : 'white',
                cursor: 'pointer',
              }}
              onClick={() => {
                setSearchAll(!searchAll);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setSearchAll(!searchAll);
                }
              }}
            ></div>
          </div>
        }
      </div>

      <div className='laminate-scrollbar-div'>
        <Scrollbar
          style={{ width: 535, height: 163 }}
          className='laminates-scroll'
        >
          <div
            className='laminates-display'
            id={`laminates-display${assignClass ? `-${assignClass}` : ''}`}
            onMouseLeave={() => {
              setTimeout(() => {
                setInfoHover(false);
                // setColorData();
              }, 2500);
            }}
          >
            {searchAll ?
              searchLaminates(laminates)
            : selectedLaminateType === 'standard' ?
              searchLaminates(colorsArrayStandard)
            : selectedLaminateType === 'premium' ?
              searchLaminates(colorsArrayPremium)
            : selectedLaminateType === 'quickShip' ?
              searchLaminates(quickShipColorsArray)
            : ''}
          </div>
        </Scrollbar>
      </div>
    </div>
  );
};

export default LaminateBar;
