import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import Axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';
import { LuBox } from 'react-icons/lu';
import { SlArrowLeft, SlArrowRight } from 'react-icons/sl';
import { useDispatch, useSelector } from 'react-redux';
import SwatchSamples from '../../Components/Products/SwatchSamples';
import FormatImageLink from '../../Components/Shared/FormatImageLink';
import '../../CSS/Configura3d.css';
import useGeneralImages from '../../hooks/useGeneralImages';
import useUserInfo from '../../hooks/useUserInfo';
import { updateProductInfo } from '../../ReduxSlices/productState';
const ProductView = React.lazy(() => import('../../Components/Products/Configura3d'));

const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const MainProductImage = () => {
  const { generalImages } = useGeneralImages();
  const { displayBoolean } = useUserInfo();
  const {
    product,
    filterExceptionList,
    optionsSelected,
    finishTypes,
    mainImage,
    showQuickshipIcon,
    allProducts,
    skuCompleted,
    view3d,
  } = useSelector((state) => state.productState.value);
  const [configuraSku, setConfiguraSku] = useState();
  const [productViewParam, setProductViewParam] = useState();
  const dispatch = useDispatch();
  const decodeAndFormat = FormatImageLink();
  const [statusIcons, setStatusIcons] = useState();
  const [tryOpen3d, setTryOpen3d] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const patentIcons = [
    {
      type: 'Patent',
      image: generalImages.find((i) => i.publicId === 'Stamp_Patented_72x72_gml0b0')?.imageLink,
    },
    {
      type: 'Patent Pending',
      image: generalImages.find((i) => i.publicId === 'Stamp_Patent_pending_muqqix')?.imageLink,
    },
  ];

  function setNextImg() {
    let imageIndex = -1;
    for (let i = 0; i < product.cdnLinks.length; i++) {
      if (product.cdnLinks[i].Image === mainImage) {
        imageIndex = i + 1;
      }
    }
    if (imageIndex >= product?.cdnLinks.length) {
      imageIndex = 0;
    }
    dispatch(
      updateProductInfo({
        mainImage: product?.cdnLinks[imageIndex].Image,
        mainImageIndex: imageIndex,
      })
    );
  }

  function setPrevImage() {
    var imageIndex = -1;
    for (var i = 0; i < product?.cdnLinks.length; i++) {
      if (product?.cdnLinks[i].Image === mainImage) {
        imageIndex = i - 1;
      }
    }

    if (imageIndex === -1) {
      imageIndex = product?.cdnLinks.length - 1;
    }
    dispatch(
      updateProductInfo({
        mainImage: product?.cdnLinks[imageIndex].Image,
        mainImageIndex: imageIndex,
      })
    );
  }

  function getStatusIcons() {
    Axios.get(`${apiAddress}/products/statusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }

  useEffect(() => {
    getStatusIcons();
  }, []);

  useEffect(() => {
    if (tryOpen3d && !displayBoolean) {
      dispatch(updateProductInfo({ view3d: !view3d }));
      setTryOpen3d(false);
    }
  }, [tryOpen3d, displayBoolean]);

  useEffect(() => {
    if (product?.productInfo?.configuraSeries) {
      setConfiguraSku(product?.productInfo?.configuraSeries);
    } else {
      Axios.post(`${apiAddress}/products/getConfiguraMissing`, {
        baseModelNum: product.productInfo.BaseModelNum,
      })
        .then((response) => {
          if (response.data.length === 0) {
            setConfiguraSku(
              product.productInfo.configuraMatch === 'Yes' ? product.productInfo.BaseModelNum
              : product.productInfo.configuraMatch === 'No' ?
                product.productInfo.BaseModelNum.replace(/x/g, '')
              : product.productInfo.configuraMatch
            );
          } else {
            setConfiguraSku(undefined);
          }
        })
        .catch((error) => {
          console.error('Error getting configura not found list:', error);
        });
    }
  }, [product]);

  useEffect(() => {
    setProductViewParam({
      partNumber: decodeURIComponent(`${configuraSku}`),
    });
  }, [configuraSku]);

  return (
    <div className='product-image'>
      {configuraSku && !mobileScreen && (
        <button
          className={`view-3d-toggle ${view3d ? 'selected-3d' : ''}`}
          onClick={() => {
            dispatch(updateProductInfo({ view3d: !view3d }));
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              dispatch(updateProductInfo({ view3d: !view3d }));
            }
          }}
        >
          <LuBox />
          <span>3D VIEW</span>
        </button>
      )}

      {view3d && configuraSku ?
        <Suspense fallback={<div className='product-image-holder-div'>Loading...</div>}>
          <ProductView prodParamsPreMemo={productViewParam} />
          <div className='special-margin product-status-info-div'>
            {(
              (product?.productInfo?.Status !== 'A' &&
                (allProducts.length > 1 ? skuCompleted : true)) ||
              allProducts.every((i) => i?.productInfo?.Status === 'C')
            ) ?
              <div className='stock-div'>
                <img
                  className='stock-div-img'
                  src={`${
                    statusIcons?.find(
                      (i) => i.status?.toLowerCase() === product?.productInfo?.Status?.toLowerCase()
                    )?.icon_link
                  }`}
                  alt='Status Icon'
                ></img>
              </div>
            : <div style={{ width: '210px', height: '67px' }}></div>}

            <div className='patent-icon-div'>
              {product?.productInfo?.PatentType ?
                <img
                  src={`${
                    patentIcons?.find((i) => i.type === product?.productInfo?.PatentType)?.image
                  }`}
                  alt='Exclusive Patent Icon'
                ></img>
              : ' '}

              {showQuickshipIcon ?
                <img
                  src={
                    generalImages.find((i) => i.publicId === 'Quick_Ship_Green_yasao8')?.imageLink
                  }
                  alt='Quick Ship Logo'
                ></img>
              : ''}
            </div>
          </div>
        </Suspense>
      : <>
          <SwatchSamples
            filterExceptionList={filterExceptionList}
            optionsSelected={optionsSelected}
            finishTypes={finishTypes}
            product={product}
          />
          <SlArrowLeft
            onClick={setPrevImage}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setPrevImage();
              }
            }}
            className='left-arrow'
          />
          <div
            role='button'
            tabIndex={0}
            aria-label='Main Prouct Display'
            className='product-img-display'
            onClick={() => {
              // eslint-disable-next-line no-console
              console.log(product);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // eslint-disable-next-line no-console
                console.log(product);
              }
            }}
          >
            <AdvancedImage
              cldImg={decodeAndFormat(mainImage).resize(scale().width(1000))}
              alt={product.modelNum}
            />
            <div className='product-status-info-div'>
              {(
                (product?.productInfo?.Status !== 'A' &&
                  (allProducts.length > 1 ? skuCompleted : true)) ||
                allProducts.every((i) => i?.productInfo?.Status === 'C')
              ) ?
                <div className='stock-div'>
                  <img
                    className='stock-div-img'
                    src={`${
                      statusIcons?.find(
                        (i) =>
                          i.status?.toLowerCase() === product?.productInfo?.Status?.toLowerCase()
                      )?.icon_link
                    }`}
                    alt='Coming Soon'
                  ></img>
                </div>
              : <div style={{ width: '210px', height: '60px' }}></div>}

              <div className='patent-icon-div'>
                {product?.productInfo?.PatentType ?
                  <img
                    src={`${
                      patentIcons?.find((i) => i.type === product?.productInfo?.PatentType)?.image
                    }`}
                    alt='Exclusive Patent Icon'
                  ></img>
                : ' '}
                {showQuickshipIcon ?
                  <img
                    src={
                      generalImages.find((i) => i.publicId === 'Quick_Ship_Green_yasao8')?.imageLink
                    }
                    alt='Quick Ship Logo'
                  ></img>
                : ' '}
              </div>
            </div>
          </div>
          <SlArrowRight
            onClick={setNextImg}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setNextImg();
              }
            }}
            className='right-arrow'
          />
        </>
      }
    </div>
  );
};

export default MainProductImage;
