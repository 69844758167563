import { useLocation } from 'react-router-dom';
import EditTemplate from '../EditorPortal/EditTemplate';

const AccountRequests = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.toString();
  let decoded;
  if (queryString) {
    decoded = decodeURIComponent(queryString)?.substring(queryString?.indexOf('=') + 1);
  }

  const textDesc =
    'View all users that have been assigned an account and are waiting for approval.';
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'account_add_requests',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'User ID',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'user_ID',
    },
    {
      type: 'text',
      name: 'Email',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'email',
    },
    {
      type: 'text',
      name: 'Account #',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'account_number',
    },
    {
      type: 'text',
      name: 'Account Name',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'account_name',
    },
    {
      type: 'text',
      name: 'Date Submitted',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'date_submitted',
    },
    {
      type: 'text',
      name: 'Approvals',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'account_add_requests',
      fieldName: 'approvals',
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={'pendingAccounts'}
      title={'Pending Approvals'}
      table={'account_add_requests'}
      exportAvailable={true}
      addHandlerIncoming={null}
      searchInput={decoded}
      textDesc={textDesc}
    />
  );
};

export default AccountRequests;
